import type { ReactElement, ReactNode } from "react"
import React from "react"
import _t from "@core/i18n"
import IconOccupied from "@onestore-icons/domain-search/icon_occupied.svg"
import IconUnavailable from "@onestore-icons/domain-search/icon_unavailable.svg"
import { navigate } from "gatsby"
import type { DomainCheck } from "@onestore/api/domainSearch"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import UnderlineLinkAtm from "@onestore/hel/dist/components/atoms/UnderlineLinkAtm"
import DomainNameMol from "@onestore/hel/dist/components/molecules/DomainNameMol"
import DomainSearchResultBoxOrg from "@onestore/hel/dist/components/organisms/DomainSearchResultBoxOrg"
import type { AdditionalInfoProps } from "@onestore/hel/dist/components/organisms/DomainSearchResultBoxOrg/AdditionalInfo"
import type { SubItemProps } from "@onestore/hel/dist/components/organisms/DomainSearchResultBoxOrg/SubItem"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import useBreakpoint from "@onestore/hel/dist/hooks/useBreakpoint"
import ScrollToCategoriesButton from "@gatsby-plugin-domain-search/components/Button/ScrollToCategoriesButton"
import type { LocationTransferState } from "~/components/DomainTransferInputField"
import useSiteMetadataQuery from "~/hooks/useSiteMetadataQuery"
import { md } from "~/lib/i18n"
import isEmpty from "~/lib/isEmpty"
import { TextAtmBoldError, TextAtmBoldSecondary } from "~/lib/markdown"
import { COMPONENT_ID } from "~/types/component"

interface DomainTransferResultBoxPros {
  domain: DomainCheck.Result
  subItems: SubItemProps[] | undefined
  footer?: ReactNode
  unavailableExtensions: string[]
  priceToggle?: ReactNode
  additionalInfo?: AdditionalInfoProps
}

export default function DomainTransferResultBox({
  domain,
  subItems,
  footer,
  priceToggle,
  additionalInfo,
}: DomainTransferResultBoxPros): ReactElement<DomainTransferResultBoxPros> {
  const { transferPagePath, ogSiteName } = useSiteMetadataQuery()
  const isSmallOnly = useBreakpoint("small", "medium")

  return (
    <DomainSearchResultBoxOrg
      header={
        <TextAtm typography="small1">
          {isEmpty(additionalInfo)
            ? md("domainSearch.searchedDomainIsNotAvailable", undefined, {
                strong: TextAtmBoldError,
              })
            : md("domainSearch.searchedDomainIsOccupied", undefined, {
                strong: TextAtmBoldSecondary,
              })}
        </TextAtm>
      }
      image={isEmpty(additionalInfo) ? <IconUnavailable /> : <IconOccupied />}
      main={
        <div id={COMPONENT_ID.DOMAIN_SEARCH_MAIN_RESULT}>
          <PushOrg bottomSpace={0.5}>
            <DomainNameMol
              typography="large2"
              align={isSmallOnly && !additionalInfo ? "center" : undefined}
              name={domain.name}
              extension={domain.extension}
              extensionColor={isEmpty(additionalInfo) ? "error" : "secondary"}
              isExtensionBolded
            />
          </PushOrg>

          <UnderlineLinkAtm
            typography="tiny1"
            title={_t("domainSearch.mainDomainInfoTextTransfer", {
              brand: ogSiteName,
            })}
            href="#"
            onClick={(event) => {
              event.preventDefault()

              navigate(transferPagePath, {
                state: {
                  domainTransfer: domain,
                },
              } as LocationTransferState)
            }}
          >
            {_t("domainSearch.mainDomainInfoTextTransfer", {
              brand: ogSiteName,
            })}
          </UnderlineLinkAtm>
        </div>
      }
      aside={
        <ScrollToCategoriesButton
          isExpandedOnMobile={!!additionalInfo}
          isWider={!!additionalInfo}
        />
      }
      footer={footer}
      subItems={subItems}
      priceToggle={priceToggle}
      additionalInfo={additionalInfo}
    />
  )
}
