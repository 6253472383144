import { pick } from "lodash"
import type { BasketPatchItem, JSONPatch, Resource } from "@onestore/api/types"

const createBasketResource = (resource: any): any => ({
  resource: resource.id,
  quantity: resource.quantity,
})

const createBasketItem = (item: BasketPatchItem): BasketPatchItem => {
  const basketItem: BasketPatchItem = {
    plan: item.plan,
    parameters: item.parameters,
    quantity: 1,
  }

  if (item.planPeriod) {
    basketItem.planPeriod = item.planPeriod
  }

  if (item.resources) {
    basketItem.resources = item.resources.map(createBasketResource)
  }

  if (item.children) {
    basketItem.children = item.children.map(createBasketItem)
  }

  if (item.ignoreHack) {
    basketItem.ignoreHack = item.ignoreHack
  }

  if (item.quantity) {
    basketItem.quantity = item.quantity
  }

  return basketItem
}

export const removeBasketResource = (
  itemId: number,
  resourceId: number
): JSONPatch => ({
  op: "remove",
  path: `/items/${itemId}/resources/${resourceId}`,
})

export const clearBasket = (): JSONPatch => ({
  op: "remove",
  path: `/items`,
})

export const addBasketResource = (
  itemId: number,
  resourceId: number,
  quantity: number = 1
): JSONPatch => ({
  op: "add",
  path: `/items/${itemId}/resources`,
  value: createBasketResource({
    id: resourceId,
    quantity,
  }),
})

export const addBasketItem = (item: BasketPatchItem): JSONPatch => ({
  op: "add",
  path: "/items",
  value: createBasketItem(item),
})

export const changePromoCode = (promoCode: string): JSONPatch => ({
  op: "replace",
  path: "/promo_code",
  value: promoCode || "",
})

export const changePaymentMethod = (
  paymentMethod: null | number
): JSONPatch => ({
  op: "replace",
  path: "/payment_method",
  value: paymentMethod,
})

export const addBasketItemChild = (
  itemId: number,
  child: BasketPatchItem
): JSONPatch => ({
  op: "add",
  path: `/items/${itemId}/children`,
  value: createBasketItem(child),
})

export const itemsBasketPrice = (items: BasketPatchItem[]): any =>
  items.map(createBasketItem)

export const replaceItemResources = (
  itemId: number,
  resources: any
): JSONPatch =>
  resources.map(
    (resource: Resource): JSONPatch => ({
      op: "replace",
      path: `/items/${itemId}/resources/${resource.id}`,
      value: pick(resource, ["quantity", "resource"]),
    })
  )

export const replaceItemParameter = (
  itemId: number,
  parameterId: string,
  value: any
): JSONPatch => ({
  op: "replace",
  path: `/items/${itemId}/parameters/${parameterId}`,
  value,
})
