import type { ResolvedOptionsMapping } from "@gatsby-plugin-definitions/lib/sale-configuration"
import { getSelectedPlanSettings } from "../utils"
import useSaleConfigurationState from "./useSaleConfigurationState"

export default function useProductSettings(
  saleConfigurationId
): ResolvedOptionsMapping {
  const { optionsMapping, options, defaultOptions } =
    useSaleConfigurationState(saleConfigurationId)

  return getSelectedPlanSettings(optionsMapping, options, defaultOptions)
}
