import { useStaticQuery, graphql } from "gatsby"
import type { DomainBundle } from "@gatsby-plugin-domain-search/fragments/domainBundle"
import isEmpty from "~/lib/isEmpty"
import type { DomainBundleHook } from "./useMainDomainBundleQuery"

interface Query {
  GraphCmsSquidex: {
    findDomainSearchPageSingleton: {
      id: string
      flatData: {
        domainAXBundle?: DomainBundle
      }
    }
  }
}

export default function useMainDomainBundleQuery(): DomainBundleHook {
  const {
    GraphCmsSquidex: { findDomainSearchPageSingleton },
  } = useStaticQuery<Query>(graphql`
    {
      GraphCmsSquidex {
        findDomainSearchPageSingleton {
          id
          flatData {
            domainAXBundle {
              ...DomainBundle
            }
          }
        }
      }
    }
  `)

  if (
    isEmpty(findDomainSearchPageSingleton) ||
    !findDomainSearchPageSingleton.flatData.domainAXBundle
  ) {
    return {
      title: null,
      extensions: [],
    }
  }

  return {
    title: findDomainSearchPageSingleton.flatData.domainAXBundle.text,
    extensions:
      findDomainSearchPageSingleton.flatData.domainAXBundle.extensions.map(
        (item): string => item.flatData.extension
      ),
  }
}
