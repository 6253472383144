import { useStaticQuery, graphql } from "gatsby"
import type { CloudBluePeriod } from "@gatsby-plugin-definitions/fragments/CloudBluePeriod"
import type { DomainBundle } from "@gatsby-plugin-domain-search/fragments/domainBundle"
import isEmpty from "~/lib/isEmpty"

export interface DomainLink {
  extension: string
  to: string
  periods: CloudBluePeriod[]
}

interface Query {
  GraphCmsSquidex: {
    findDomainSearchPageSingleton: {
      id: string
      flatData: {
        domainMainBundle?: DomainBundle
      }
    }
  }
}

export interface DomainBundleHook {
  title: string | null
  extensions: string[]
}

export default function useMainDomainBundleQuery(): DomainBundleHook {
  const {
    GraphCmsSquidex: { findDomainSearchPageSingleton },
  } = useStaticQuery<Query>(graphql`
    {
      GraphCmsSquidex {
        findDomainSearchPageSingleton {
          id
          flatData {
            domainMainBundle {
              ...DomainBundle
            }
          }
        }
      }
    }
  `)

  if (
    isEmpty(findDomainSearchPageSingleton) ||
    !findDomainSearchPageSingleton.flatData.domainMainBundle
  ) {
    return {
      title: null,
      extensions: [],
    }
  }

  return {
    title: findDomainSearchPageSingleton.flatData.domainMainBundle.text,
    extensions:
      findDomainSearchPageSingleton.flatData.domainMainBundle.extensions.map(
        (item): string => item.flatData.extension
      ),
  }
}
