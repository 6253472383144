import type { KeyboardEventHandler, ReactElement } from "react"
import React from "react"
import _t from "@core/i18n"
import Search from "@onestore-icons/search.svg"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import UnderlineLinkAtm from "@onestore/hel/dist/components/atoms/UnderlineLinkAtm"
import SubmitInputMol from "@onestore/hel/dist/components/molecules/SubmitInputMol"
import TooltipMol from "@onestore/hel/dist/components/molecules/TooltipMol"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import useBreakpoint from "@onestore/hel/dist/hooks/useBreakpoint"
import {
  isResponseFailed,
  isResponsePending,
  isResponseSuccessful,
} from "@onestore/onestore-store-common/http"
import { md } from "~/lib/i18n"

interface SubmitFieldProps {
  disabled: boolean
  requestStatus: number
  pool?: number
  onNextStepClick?: { (): void }
  nextStep?: boolean | null
  hideText?: boolean
  phrase: string
  setPhrase: { (string): void }
  bundleDomainsExtensions?: string[]
  onSearchClicked: {
    (
      phrase: string,
      pool: number | undefined,
      bundleDomainsExtensions?: string[]
    ): void
  }
}

export default function SubmitField({
  disabled,
  requestStatus,
  pool,
  nextStep,
  hideText,
  onNextStepClick,
  phrase,
  setPhrase,
  bundleDomainsExtensions,
  onSearchClicked,
}: SubmitFieldProps): ReactElement | null {
  const isMediumUp = useBreakpoint("medium")

  if (disabled) {
    return null
  }

  const handleDomainInputKeyPress: KeyboardEventHandler<HTMLInputElement> = (
    event
  ) => {
    if (event.key !== "Enter" && event.keyCode !== 13) {
      return
    }

    onSearchClicked(phrase, pool, bundleDomainsExtensions)
  }

  const handleOnChange = (event) => {
    const value = event.currentTarget.value

    setPhrase(value)
  }

  return (
    <>
      <PushOrg bottomSpace={3}>
        <SubmitInputMol
          hasError={isResponseFailed(requestStatus)}
          hasSuccess={isResponseSuccessful(requestStatus)}
          isLoading={isResponsePending(requestStatus)}
          onKeyPress={handleDomainInputKeyPress}
          value={phrase}
          placeholder={_t("domainSearch.inputPlaceholder")}
          buttonText={_t("domainSearch.submit")}
          buttonTitle={_t("domainSearch.submit")}
          buttonColor="success"
          buttonMobileIcon={Search}
          hasWiderButton
          onChange={handleOnChange}
          onSubmit={() => {
            onSearchClicked(phrase, pool, bundleDomainsExtensions)
          }}
          className="t-input"
          name="domainsearch"
          buttonClassName="t-click"
          buttonDataTName="search"
        />
      </PushOrg>

      <FlexContainerOrg
        alignItems="center"
        justifyContent="space-between"
        direction={isMediumUp ? undefined : "column"}
      >
        {!hideText ? (
          <TooltipMol
            labelTypography="tiny2"
            iconSize="small"
            labelText={_t("preBasket.searchSortInfoLabel")}
          >
            <TextAtm typography="nano1">
              {md(
                "preBasket.searchSortInfoTooltip",
                undefined,
                undefined,
                true
              )}
            </TextAtm>
          </TooltipMol>
        ) : null}

        {nextStep ? (
          <UnderlineLinkAtm
            onClick={onNextStepClick}
            typography="tiny2"
            title={_t("preBasket.nextStepWithoutDomain")}
            href="#"
          >
            {_t("preBasket.nextStepWithoutDomain")}
          </UnderlineLinkAtm>
        ) : null}
      </FlexContainerOrg>
    </>
  )
}
