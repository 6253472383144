import type { ThunkDispatch } from "redux-thunk"
import type {
  ResolvedOptionsMapping,
  SaleConfiguration,
} from "@gatsby-plugin-definitions/lib/sale-configuration"
import type { SaleConfiguration as SaleConfigurationType } from "~/fragments/saleConfigurations"
import type { AppState } from "~/store/reducer"
import type { SaleConfigurationState } from "./selectors"

export enum ACTIONS {
  SALE_CONFIGURATION_VALUE_CLICK = "@widgets/SALE_CONFIGURATION_VALUE_CLICK",
  SALE_CONFIGURATION_INIT = "@widgets/SALE_CONFIGURATION_INIT",
}

export function getSaleConfigurationSelectedValue(
  control: SaleConfiguration.Control,
  options: SaleConfiguration.Options
): string {
  const values: string[] = control.options
    .filter(
      (option: SaleConfiguration.DropdownOption): boolean =>
        option.value === options[control.name] || false
    )
    .map((option: SaleConfiguration.DropdownOption): string => option.name)

  if (values.length === 0) {
    throw new Error("SaleConfiguration - got empty values set")
  }

  return values.pop() as string
}

export function saleConfigurationDefaultValues(
  box: SaleConfigurationType
): SaleConfigurationState {
  const options = {}
  const values = {}
  const controls = box.flatData.config.controls || []
  const defaultOptions = box.flatData.config.defaultOptions || {}

  controls.forEach((control: SaleConfiguration.Control) => {
    if (control.type === "select") {
      control.options.forEach((option: SaleConfiguration.DropdownOption) => {
        if (option.selected) {
          options[control.name] = option.value
        }
      })

      if (!options[control.name]) {
        options[control.name] = control.options[0].value
      }

      values[control.name] = getSaleConfigurationSelectedValue(control, options)
    }
  })

  const optionsMapping: ResolvedOptionsMapping[] =
    box.flatData.config.optionsMapping.map((value: ResolvedOptionsMapping) => ({
      ...defaultOptions,
      ...value,
    }))

  return {
    ...box.flatData.config,
    defaultOptions,
    options,
    values,
    optionsMapping,
  }
}

export interface SaleConfigurationValueChangeAction {
  type: ACTIONS
  payload: {
    uuid: string
    value: string
    selectedValue: string
    name: string
  }
}

export interface SaleConfigurationInitAction {
  type: ACTIONS
  payload: {
    uuid: string
    configuration: SaleConfigurationState
  }
}

export type SaleConfigurationActions = SaleConfigurationInitAction &
  SaleConfigurationValueChangeAction

export type SaleConfigurationDispatch = ThunkDispatch<
  AppState,
  any,
  SaleConfigurationActions
>

export function saleConfigurationValueChange(
  uuid: string,
  name: string,
  value: string
) {
  return (dispatch, getState) => {
    const state: AppState = getState()

    const options = { ...state.marketplace.configuration[uuid].options }

    options[name] = value

    return dispatch({
      type: ACTIONS.SALE_CONFIGURATION_VALUE_CLICK,
      payload: {
        uuid,
        name,
        value,
        selectedValue: getSaleConfigurationSelectedValue(
          state.marketplace.configuration[uuid].controls.filter(
            (item) => item.name === name
          )[0],
          options
        ),
      },
    })
  }
}

export function saleConfigurationInit(
  uuid: string,
  data: SaleConfigurationType
): SaleConfigurationInitAction {
  return {
    type: ACTIONS.SALE_CONFIGURATION_INIT,
    payload: {
      uuid,
      configuration: saleConfigurationDefaultValues(data),
    },
  }
}
