import type { ReactElement } from "react"
import React, { useCallback } from "react"
import _t from "@core/i18n"
import { formatPrice } from "@core/pricing"
import type { DomainCheck } from "lib/api/domainSearch"
import type { DomainBundlePricing } from "@onestore/api/types"
import LabelAtm from "@onestore/hel/dist/components/atoms/LabelAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import DomainSearchResultItemMol from "@onestore/hel/dist/components/molecules/DomainSearchResultItemMol"
import useAXDomainBundleQuery from "@gatsby-plugin-domain-search/hooks/useAXDomainBundleQuery"
import useDomainActionsDispatch from "@gatsby-plugin-domain-search/hooks/useDomainActionsDispatch"
import type { DomainSearch } from "@gatsby-plugin-domain-search/types"

export interface BundleDomainsResult {
  domains: DomainCheck.Result[]
  prices: DomainBundlePricing | null
  isPartialBundleInBasket: boolean
  buttonId: string
  isLoading: boolean
}

export default function BundleRow({
  isLoading,
  buttonId,
  domains,
  prices,
  isPartialBundleInBasket,
}: BundleDomainsResult): ReactElement | null {
  const { addDomainsToBasket } = useDomainActionsDispatch()
  const { title } = useAXDomainBundleQuery()

  const insertedDomains: DomainSearch.DomainFqdnWithPlan[] = domains.map(
    (domain): DomainSearch.DomainFqdnWithPlan => {
      return {
        fqdn: domain.fqdn,
        plan: domain.plan_id,
      }
    }
  )

  const onClick = useCallback(() => {
    addDomainsToBasket(
      insertedDomains.slice(0, 1),
      insertedDomains.slice(1),
      buttonId,
      false
    )
  }, [insertedDomains, isPartialBundleInBasket])

  if (prices === null) {
    return null
  }

  const period = prices.period_value
    ? ` / ${_t(`periodName.searchDomainPrice.${prices.period_type}`, {
        smart_count: prices.period_value,
      })}`
    : ""

  return (
    <DomainSearchResultItemMol
      domain={domains.map((domain) => ({
        name: domain.name,
        extension: `.${domain.extension}`,
      }))}
      price={
        <TextAtm typography="medium3_s">
          {_t("domainSearch.total")} {formatPrice(prices.register_price_gross)}{" "}
          <TextAtm typography="xsmall1">{period}</TextAtm>
        </TextAtm>
      }
      isLoading={isLoading}
      button={{
        text: _t("domainSearch.button.addToCart"),
        title: _t("domainSearch.button.addToCart"),
        onClick,
      }}
      caption={
        <LabelAtm size="small" variant="notifyLight">
          {title || _t("domainSearch.groupHeaderText")}
        </LabelAtm>
      }
    />
  )
}
