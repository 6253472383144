import type { ReactElement, ReactNode } from "react"
import React from "react"
import PremiumLogo from "@onestore-icons/domain-search/premium-logo.svg"
import type { DomainCheck } from "@onestore/api/domainSearch"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import DomainSearchResultBoxOrg from "@onestore/hel/dist/components/organisms/DomainSearchResultBoxOrg"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import PremiumButton from "@gatsby-plugin-domain-search/components/Button/PremiumButton"
import ScrollToCategoriesButton from "@gatsby-plugin-domain-search/components/Button/ScrollToCategoriesButton"
import { DomainName } from "@gatsby-plugin-domain-search/components/MainResultBox/DomainName"
import { Footer } from "@gatsby-plugin-domain-search/components/MainResultBox/Footer"
import { MainResultImage } from "@gatsby-plugin-domain-search/components/MainResultBox/MainResultImage"
import type { CtaSectionsModal } from "@gatsby-plugin-generic-page/fragments/ctaSectionsModal"
import { md } from "~/lib/i18n"
import isEmpty from "~/lib/isEmpty"
import { TextAtmBold } from "~/lib/markdown"
import { COMPONENT_ID } from "~/types/component"

interface PremiumMainResultBoxProps {
  premiumDomain: DomainCheck.ExternalSearchResult
  sortingTooltipContent?: string | null
  priceToggle?: ReactNode
  ctaSectionsModal?: CtaSectionsModal
}

export default function PremiumMainResultBox({
  premiumDomain,
  priceToggle,
  ctaSectionsModal,
  sortingTooltipContent,
}: PremiumMainResultBoxProps): ReactElement<PremiumMainResultBoxProps> | null {
  const availableText = md(
    "domainSearch.mainDomainHeaderTextRecommended",
    undefined,
    {
      strong: TextAtmBold,
    }
  )

  return (
    <div id={COMPONENT_ID.DOMAIN_SEARCH_MAIN_RESULT}>
      <DomainSearchResultBoxOrg
        header={<TextAtm typography="small1">{availableText}</TextAtm>}
        image={<MainResultImage isPhraseIdenticalToFqdn={false} available />}
        main={
          <FlexContainerOrg direction="row" alignItems="center" gapSpace={2}>
            <DomainName domain={premiumDomain} extensionColor="secondary" />

            <PremiumLogo />
          </FlexContainerOrg>
        }
        aside={
          <FlexContainerOrg direction="column" gapSpace={2}>
            {!isEmpty(premiumDomain.external_url) ? (
              <PremiumButton
                url={premiumDomain.external_url}
                showTextOnMobile
              />
            ) : undefined}

            <ScrollToCategoriesButton isExpandedOnMobile />
          </FlexContainerOrg>
        }
        footer={
          <Footer
            ctaSectionsModal={ctaSectionsModal}
            sortingTooltipContent={sortingTooltipContent}
          />
        }
        priceToggle={priceToggle}
      />
    </div>
  )
}
