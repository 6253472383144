export const COOKIES = {
  PRODUCT_TYPES: {
    NAME: "_typesInfo",
    TTL: 10 * 365,
    DATA_SEPARATOR: ",",
  },
  REMEMBER_ME: "_rememeber_me",
}

export enum CheckoutActions {
  USER_DATA_PENDING = "CHECKOUT.ACTIONS.USER_DATA.PENDING",
  USER_DATA_SUCCESS = "CHECKOUT.ACTIONS.USER_DATA.SUCCESS",
  USER_DATA_FAILURE = "CHECKOUT.ACTIONS.USER_DATA.FAILURE",
  AUTH_PENDING = "CHECKOUT.ACTIONS.AUTH.PENDING",
  AUTH_SUCCESS = "CHECKOUT.ACTIONS.AUTH.SUCCESS",
  AUTH_FAILURE = "CHECKOUT.ACTIONS.AUTH.FAILURE",
  AUTH_LOGIN_RESET = "CHECKOUT.ACTIONS.AUTH.LOGIN_RESET",
  AUTH_LOGIN_SKIP = "CHECKOUT.ACTIONS.AUTH.LOGIN_SKIP",
  AUTH_UNKNOWN_LOGIN = "CHECKOUT.ACTIONS.AUTH.UNKNOWN_LOGIN",
  ORDER_SUCCESS = "CHECKOUT.ACTIONS.ORDER.SUCCESS",
  ORDER_FAILURE = "CHECKOUT.ACTIONS.ORDER.FAILURE",
  ORDER_PENDING = "CHECKOUT.ACTIONS.ORDER.PENDING",
  PAYMENT_PENDING = "CHECKOUT.ACTIONS.PAYMENT.PENDING",
  PAYMENT_SUCCESS = "CHECKOUT.ACTIONS.PAYMENT.SUCCESS",
  PAYMENT_FAILURE = "CHECKOUT.ACTIONS.PAYMENT.FAILURE",
  USER_REGISTER_SUCCESS = "CHECKOUT.ACTIONS.USER_REGISTER.SUCCESS",
  USER_UPDATE_SUCCESS = "CHECKOUT.ACTIONS.USER_UPDATE.SUCCESS",
  BASKET_PENDING = "CHECKOUT.ACTIONS.BASKET.PENDING",
  BASKET_SUCCESS = "CHECKOUT.ACTIONS.BASKET.SUCCESS",
  BASKET_FAILURE = "CHECKOUT.ACTIONS.BASKET.FAILURE",
  RESET = "ACTIONS.CHECKOUT.RESET",
  USER_PENDING = "USER.ACTIONS.USER.PENDING",
  USER_SUCCESS = "USER.ACTIONS.USER.SUCCESS",
  USER_FAILURE = "USER.ACTIONS.USER.FAILURE",
  BASKET_TERMS_PENDING = "CHECKOUT.ACTIONS.BASKET_TERMS.PENDING",
  BASKET_TERMS_SUCCESS = "CHECKOUT.ACTIONS.BASKET_TERMS.SUCCESS",
  BASKET_TERMS_FAILURE = "CHECKOUT.ACTIONS.BASKET_TERMS.FAILURE",
  BASKET_TERMS_TOGGLE_ALL_TERMS = "CHECKOUT.ACTIONS.BASKET_TERMS.TOGGLE_ALL_TERMS",
  BASKET_TERMS_TOGGLE_TERM = "CHECKOUT.ACTIONS.BASKET_TERMS.TOGGLE_TERM",
  BASKET_PARAMS_GET_PENDING = "CHECKOUT.ACTIONS.BASKET_PARAMS.GET.PENDING",
  BASKET_PARAMS_GET_SUCCESS = "CHECKOUT.ACTIONS.BASKET_PARAMS.GET.SUCCESS",
  BASKET_PARAMS_GET_FAILURE = "CHECKOUT.ACTIONS.BASKET_PARAMS.GET.FAILURE",
  BASKET_PARAMS_SAVE_PENDING = "CHECKOUT.ACTIONS.BASKET_PARAMS.SAVE.PENDING",
  BASKET_PARAMS_SAVE_SUCCESS = "CHECKOUT.ACTIONS.BASKET_PARAMS.SAVE.SUCCESS",
  BASKET_PARAMS_SAVE_FAILURE = "CHECKOUT.ACTIONS.BASKET_PARAMS.SAVE.FAILURE",
  CHECKOUT_RESET = "CHECKOUT.ACTIONS.CHECKOUT.RESET",
  PERSON_DATA_SAVE = "CHECKOUT.PERSON_DATA.SAVE",
  COMPANY_DATA_SAVE = "CHECKOUT.COMPANY_DATA.SAVE",
  ADDRESS_CONFIRMATION_CLOSE = "CHECKOUT.ADDRESS_CONFIRMATION_CLOSE",
  ADDRESS_CONFIRMATION_OPEN = "CHECKOUT.ADDRESS_CONFIRMATION_OPEN",
  BASKET_TERMS_VALIDATION = "CHECKOUT.ACTIONS.BASKET_TERMS_VALIDATION",
  USER_EDIT_DATA_MODAL_OPEN = "CHECKOUT.ACTIONS.USER_EDIT_DATA_MODAL_OPEN",
  USER_EDIT_DATA_MODAL_CLOSE = "CHECKOUT.ACTIONS.USER_EDIT_DATA_MODAL_CLOSE",
}

export enum CheckoutConflictTypes {
  ADDRESS_CONFIRMATION_NEEDED = "address_confirmation_needed",
}
