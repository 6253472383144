import { getCurrencyCode } from "@core/pricing"
import { forEach, forIn, get, trimEnd, values } from "lodash"
import type {
  BasketResponse,
  BasketResponseItem,
  BasketResponseResource,
} from "@onestore/api/basket"
import type { AccountType } from "@onestore/api/types"
import type {
  BasketProductItem,
  BasketStateItem,
  BasketStateResource,
} from "@onestore/onestore-store-common"
import type {
  EcommerceAddProductDataLayer,
  EcommerceCheckoutDataLayer,
  EcommerceOrderDataLayer,
  TransactionProduct,
} from "~/lib/dataLayer"
import { dataLayerPush } from "~/lib/dataLayer"
import isEmpty from "~/lib/isEmpty"
import { hasLock, LockType, setLock } from "~/lib/lock"
import vars from "~/lib/onestoreVars"
import { getBasketItemPricePerUnitFormatted } from "~/lib/pricing"
import Storage from "~/lib/storage"
import SessionStorage from "~/lib/storage/SessionStorage"
import type { AppState } from "~/store/reducer"
import { EventCategory } from "../ga4"

export type TrackingItem =
  | BasketStateItem
  | BasketResponseItem
  | BasketStateResource
  | BasketResponseResource
  | BasketProductItem

export interface TrackingContextType {
  item: TrackingItem
  name: string
  category: string
  parentProduct: string | null | undefined
}

export interface BasketOrderProceedEvent {
  basket: {
    orderId: number
    orderNumber: string
    accountType: AccountType
    items: BasketStateItem[]
    totalValue: number
    cart_id: string
    token: string
  }
  basketOrderedItems: BasketStateItem[]
  result: {
    id: number
    number: string
  }
}

// Required legacy GA3 - ONESTORE-399
export interface OrderProceedEvent {
  detail: {
    basket: {
      orderId: number
      orderNumber: string
      accountType: AccountType
      items: BasketStateItem[]
      totalValue: number
      cart_id: string
    }
  }
}

// Required legacy GA3 - ONESTORE-399
export enum GAEvent {
  ADD_TO_CART = "addToCart",
  ORDER = "order",
  CART = "cart",
}

const nameResolver = (item: TrackingItem): string => {
  const parameters = "parameters" in item ? item.parameters || {} : {}

  const periodName = "period_name" in item ? item.period_name : ""

  if (
    typeof parameters === "object" &&
    "domain" in parameters &&
    parameters.domain
  ) {
    return trimEnd(`${`Domena ${item.alias}`} ${periodName}`)
  }

  if (
    typeof parameters === "object" &&
    "pmail" in parameters &&
    parameters.pmail
  ) {
    //@ts-ignore - naprawić po przeniesieniu paczki ~/lib/definitions (ONESTORE-5017)
    return trimEnd(`${`PMail ${item.alias}`} ${periodName}`)
  }

  return trimEnd(`${item.name} ${periodName}`)
}

const EVENTS = {
  "ONESTORE.BASKET_SHOW": "basketShowEvent",
  "ONESTORE.ORDER_PROCEED": "orderProceedEvent",
  "ONESTORE.ORDER_CHECKOUT_USERNAME": "orderCheckoutUsernameEvent",
  "ONESTORE.ORDER_CHECKOUT_PASSWORD": "orderCheckoutPasswordEvent",
  "ONESTORE.ORDER_CHECKOUT_ANY_CREDENTIALS": "orderCheckoutAnyCredentialsEvent",
  "ONESTORE.ORDER_CHECKOUT_REGISTER": "orderCheckoutRegisterEvent",
  "ONESTORE.ORDER_CHECKOUT_SUMMARY": "orderCheckoutSummaryEvent",
  "ONESTORE.BASKET_ITEM_REMOVED": "basketItemRemoved",
}

const ECOMMERCE_TRACK_ITEMS_WITHOUT_SKU = true
const ECOMMERCE_DOMAIN_TYPE = "domain"

const TRACKING_PREFIX = "#t."
const REFERRER_PREFIX = "#r."

function getDimensionSettings() {
  if (!!vars.dimensions && vars.dimensions.length === 4) {
    const { dimensions } = vars

    return [
      `dimension${dimensions[0]}`,
      `dimension${dimensions[1]}`,
      `dimension${dimensions[2]}`,
      `dimension${dimensions[3]}`,
    ]
  }

  return [null, null, null, null]
}

const [
  DIMENSION_SOURCE, // onestore_Source
  DIMENSION_ITEM_ID, // onestore_BasketItemId
  DIMENSION_PARENT, // onestore_parentProductka
  DIMENSION_REFERRER, // referrer
] = getDimensionSettings()

export class Tracking {
  constructor() {
    Tracking.initOnestoreListeners()
  }

  static getCustomDimensions(item, eventName: EventCategory | null = null) {
    if (eventName !== EventCategory.ORDER) {
      return {}
    }
    const result = {}

    if (DIMENSION_ITEM_ID !== null) {
      result[DIMENSION_ITEM_ID] =
        item.category === "resource" ? `r${item.id}` : item.id || ""
    }

    if (DIMENSION_SOURCE !== null) {
      result[DIMENSION_SOURCE] = Tracking.getLocationFromStorage(item.id)
    }

    if (DIMENSION_PARENT !== null) {
      result[DIMENSION_PARENT] = item.parentProduct || ""
    }

    if (DIMENSION_REFERRER !== null) {
      result[DIMENSION_REFERRER] = Tracking.getReferrerFromStorage(item.id)
    }

    return result
  }

  static getTrackingContext(
    item: TrackingItem,
    parent: TrackingItem | null = null
  ): TrackingContextType {
    const parameters = "parameters" in item ? item.parameters || {} : {}

    const type = "type" in item ? item.type : "resource"

    return {
      item,
      name: nameResolver(item),
      category: "pmail" in parameters && parameters.pmail ? "pmail" : type,
      parentProduct: parent ? parent.alias : "",
    }
  }

  static getCheckoutTrackingData(
    params,
    basket: AppState["basket"] | null = null
  ) {
    const dataObject: EcommerceCheckoutDataLayer = {
      event: params.event || undefined,
      pageType: params.pageType || undefined,
      ecommerce: {
        checkout: {
          actionField: params.actionField,
        },
      },
    }

    if (basket && params.attachProducts) {
      dataObject.ecommerce.checkout.products = Tracking.getProducts(
        basket.items,
        EventCategory.CART
      )
    }

    return dataObject
  }

  static dataLayerPushCheckout(params, basket) {
    if (undefined === basket) {
      return
    }
    dataLayerPush(Tracking.getCheckoutTrackingData(params, basket))
  }

  static initOnestoreListeners() {
    forIn(EVENTS, (method, event) => {
      if (Tracking[method]) {
        document.addEventListener(event, Tracking[method])
      }
    })
  }

  static getCurrentLocation() {
    const {
      location: { pathname, search },
    } = document

    return document.location ? pathname + search : ""
  }

  static saveDimensionData(basketItemId) {
    if (!basketItemId) {
      return
    }

    SessionStorage.set(
      `${TRACKING_PREFIX}${basketItemId}`,
      Tracking.getCurrentLocation()
    )
    SessionStorage.set(
      `${REFERRER_PREFIX}${basketItemId}`,
      document.referrer || ""
    )
  }

  static getReferrerFromStorage(basketItemId: number) {
    return SessionStorage.get(`${REFERRER_PREFIX}${basketItemId}`) || ""
  }

  static getLocationFromStorage(itemId: number) {
    return SessionStorage.get(`${TRACKING_PREFIX}${itemId}`) || ""
  }

  static saveDimensionsDataForItems(items) {
    forEach(items, (item) => Tracking.saveDimensionData(item.basketId))
  }

  static createTrackedItem(
    itemData,
    currentEvent: EventCategory | null = null
  ) {
    return {
      name: itemData.name,
      id: itemData.sku || "",
      basketId: itemData.id,
      productPlanId: itemData.plan_id || null,
      price: getBasketItemPricePerUnitFormatted(itemData),
      category: itemData.category,
      variant: ECOMMERCE_DOMAIN_TYPE !== itemData.type ? "" : itemData.alias,
      quantity: itemData.quantity,
      ...Tracking.getCustomDimensions(itemData, currentEvent),
    }
  }

  // zwraca produkty w koszyku sformatowane pod wymagania gtm
  static getProducts(
    items: (BasketStateItem | BasketResponseItem)[],
    event: EventCategory | null = null,
    ga3Event: GAEvent | null = null
  ): TransactionProduct[] {
    const result: Record<string, TransactionProduct> = {}
    const onlyPatchedItems =
      event === EventCategory.ADD_TO_CART || ga3Event === GAEvent.ADD_TO_CART

    const addItemToResult = (context: TrackingContextType) => {
      const { item, name, category, parentProduct } = context

      if ("resources" in item && !isEmpty(item.resources)) {
        forEach(item.resources, (resource: TrackingItem) => {
          addItemToResult({
            item: resource,
            name: resource.name,
            category: "resource",
            parentProduct: null,
          })
        })
      }

      if ("children" in item) {
        forEach<TrackingItem>(item.children, (childItem) =>
          addItemToResult(Tracking.getTrackingContext(childItem, item))
        )
      }

      if (!item.sku && !ECOMMERCE_TRACK_ITEMS_WITHOUT_SKU) {
        return
      }

      const itemKey = item.sku || name

      if (result.hasOwnProperty(itemKey)) {
        const currentQuantity = result[itemKey].quantity ?? 0
        result[itemKey].quantity = item.quantity + currentQuantity

        return
      }

      if (onlyPatchedItems && !item.last_patch_operation_flag) {
        return
      }

      result[itemKey] = Tracking.createTrackedItem(
        {
          ...item,
          name,
          category,
          parentProduct,
        },
        event
      )
    }

    forEach(items, (item) => addItemToResult(Tracking.getTrackingContext(item)))

    return values(result)
  }

  // Required legacy GA3 - ONESTORE-399
  static orderProceedEvent(e: OrderProceedEvent) {
    const {
      detail: { basket },
    } = e

    if (hasLock(LockType.ORDER_EVENT, `${basket.orderId}`)) {
      return
    }

    const userType = basket.accountType === "person" ? "indywidualny" : "firma"

    const orderValue = basket.totalValue.toFixed(2).replace(".", ",")
    const order: EcommerceOrderDataLayer = {
      event: GAEvent.ORDER,
      pageType: "thank you page",
      orderId: `${basket.orderId}`,
      orderNumber: `${basket.orderNumber}`,
      orderValue,
      userType: basket.accountType ? userType : "",
      orderPriceType: Storage.getPriceType(),
      ecommerce: {
        currencyCode: getCurrencyCode(),
        purchase: {
          actionField: {
            id: `${basket.orderNumber}`,
            affiliation: basket.accountType ? userType : "",
            revenue: orderValue,
            tax: 0,
            shipping: 0,
          },
          products: Tracking.getProducts(basket.items, null, GAEvent.ORDER),
        },
      },
    }

    dataLayerPush(order)
    setLock(LockType.ORDER_EVENT, `${basket.orderId}`, true)
  }

  // Required legacy GA3 - ONESTORE-399
  static basketShowEvent(e) {
    const params = {
      pageType: "cart",
      event: GAEvent.CART,
      actionField: { step: 1 },
      attachProducts: true,
    }

    Tracking.dataLayerPushCheckout(params, get(e, "detail.basket"))
  }

  // Required legacy GA3 - ONESTORE-399
  static basketAddItemsResultsEvent(basket: BasketResponse) {
    const products: TransactionProduct[] = Tracking.getProducts(
      basket.items,
      null,
      GAEvent.ADD_TO_CART
    )

    Tracking.saveDimensionsDataForItems(products)

    if (products.length === 0) {
      return
    }

    dataLayerPush<EcommerceAddProductDataLayer>({
      event: GAEvent.ADD_TO_CART,
      ecommerce: {
        currencyCode: getCurrencyCode(),
        add: {
          products,
        },
      },
    })
  }

  // Required legacy GA3 - ONESTORE-399
  static orderCheckoutAnyCredentialsEvent(e) {
    const params = {
      event: "checkout_step1_podajmaila",
      pageType: "checkout",
      actionField: { step: 2 },
      attachProducts: true,
    }

    Tracking.dataLayerPushCheckout(params, get(e, "detail.basket"))
  }

  // Required legacy GA3 - ONESTORE-399
  static orderCheckoutRegisterEvent(e) {
    const params = {
      event: "checkout_step2_rejestracja",
      actionField: { step: 3 },
      attachProducts: true,
    }

    Tracking.dataLayerPushCheckout(params, get(e, "detail.basket"))
  }

  // Required legacy GA3 - ONESTORE-399
  static orderCheckoutSummaryEvent(e) {
    const params = {
      event: "checkout_step3_podsumowanie",
      actionField: { step: 4 },
      attachProducts: true,
    }

    Tracking.dataLayerPushCheckout(params, get(e, "detail.basket"))
  }
}

export function initTracking() {
  new Tracking()
}
