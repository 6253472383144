import type { BasketSuccessAction } from "@gatsby-plugin-basket/store/actions"
import { BasketActions } from "@gatsby-plugin-basket/store/constants"
import { basketAddItemsResultsEvent } from "~/lib/ga4"
import { Tracking } from "~/lib/tracking"

const middleware = () => (next) => (action: BasketSuccessAction) => {
  if (action.type === BasketActions.BASKET_SUCCESS) {
    Tracking.basketAddItemsResultsEvent(action.result)
    basketAddItemsResultsEvent(action.result, action.result.items)
  }

  return next(action)
}

export default middleware
