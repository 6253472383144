import type { ReactElement } from "react"
import { useState } from "react"
import { useEffect } from "react"
import React from "react"
import { get } from "lodash"
import AbsoluteIconContainerMol from "@onestore/hel/dist/components/molecules/AbsoluteIconContainerMol"
import FullScreenContainerOrg from "@onestore/hel/dist/components/organisms/FullScreenContainerOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import RelativeContainerOrg from "@onestore/hel/dist/components/organisms/RelativeContainerOrg"
import { backgrounds } from "@onestore/hel/dist/components/organisms/SectionOrg/SectionOrg.settings"
import type { Color } from "@onestore/hel/dist/components/organisms/SectionOrg/SectionOrg.types"
import SectionWrapperOrg from "@onestore/hel/dist/components/organisms/SectionWrapperOrg"
import { headerHeight } from "@onestore/hel/dist/settings"
import { CustomTextColor } from "@onestore/hel/dist/utils/getCustomColor"
import Media from "@gatsby-plugin-generic-page/components/Media"
import type { FullScreenContainerSectionFlatData } from "@gatsby-plugin-generic-page/fragments/fullScreenContainerSection"
import { GenericPageSectionsRenderer } from "@gatsby-plugin-generic-page/GenericPage"
import { scrollToAnchor } from "~/lib/dom"
import isEmpty from "~/lib/isEmpty"

export default function FullScreenContainerSection({
  logo,
  sections,
  sectionId,
  secondSectionIcon,
}: FullScreenContainerSectionFlatData): ReactElement<FullScreenContainerSectionFlatData> {
  const [isSecondSectionVisible, setIsSecondSectionVisible] = useState(false)

  useEffect(() => {
    function checkVisible() {
      const anchorId = sectionId?.anchorId

      if (!anchorId) return

      const element = document.getElementById(anchorId)

      if (!element) {
        setIsSecondSectionVisible(false)

        return
      }

      const rect = element.getBoundingClientRect()
      setIsSecondSectionVisible(rect.top <= window.innerHeight)
    }

    checkVisible()
    window.addEventListener("wheel", checkVisible)
    window.addEventListener("scroll", checkVisible)

    return () => {
      window.removeEventListener("wheel", checkVisible)
      window.removeEventListener("scroll", checkVisible)
    }
  }, [])

  const handleIconClick = () => {
    if (!isSecondSectionVisible) {
      scrollToAnchor(sectionId.anchorId)
      setIsSecondSectionVisible(true)
    }
  }

  const [firstSection, ...restSections] = sections

  const firstSectionColor: Color | undefined = get(
    firstSection,
    "flatData.sectionSettings.color"
  )

  const firstSectionCustomColor: string | undefined = get(
    firstSection,
    "flatData.sectionSettings.customColor.customBackground.color"
  )

  const secondSectionColor: Color | undefined = !isEmpty(restSections)
    ? get(restSections[0], "flatData.sectionSettings.color")
    : undefined

  const secondSectionCustomColor: string | undefined = !isEmpty(restSections)
    ? get(
        restSections[0],
        "flatData.sectionSettings.customColor.customBackground.color"
      )
    : undefined

  return (
    <FullScreenContainerOrg
      offset={headerHeight}
      logo={
        <PushOrg
          topSpace={{ small: 5, medium: 8, large: 8 }}
          leftSpace={{ small: 2, medium: 2, large: 2 }}
          hasNoReset
        >
          <Media media={logo} />
        </PushOrg>
      }
      color="white"
      isIconHidden={isSecondSectionVisible}
      onClick={handleIconClick}
      iconColor="dark"
    >
      <GenericPageSectionsRenderer sections={[firstSection]} pageId={""} />

      {!isEmpty(restSections) ? (
        <RelativeContainerOrg>
          {!isEmpty(secondSectionIcon) ? (
            <AbsoluteIconContainerMol
              color={
                secondSectionColor ? backgrounds[secondSectionColor] : undefined
              }
              customColor={secondSectionCustomColor}
              top={-36}
            >
              <Media media={secondSectionIcon} />
            </AbsoluteIconContainerMol>
          ) : null}

          <SectionWrapperOrg
            borderRadiusSize="xlarge"
            topLayerColor={
              firstSectionCustomColor
                ? {
                    background: firstSectionCustomColor,
                    text: CustomTextColor.DARK,
                  }
                : firstSectionColor
            }
          >
            <GenericPageSectionsRenderer sections={restSections} pageId={""} />
          </SectionWrapperOrg>
        </RelativeContainerOrg>
      ) : null}
    </FullScreenContainerOrg>
  )
}
