import type { ReactElement, SyntheticEvent } from "react"
import React from "react"
import _t from "@core/i18n"
import { get } from "lodash"
import FormButton from "@gatsby-plugin-generic-page/components/FormButton"
import type { Formily } from "@gatsby-plugin-generic-page/fragments/formily"
import type { ComparableProduct } from "~/fragments/comparableProduct"
import isEmpty from "~/lib/isEmpty"
import { Button } from "./Button"
import { CurrentProductButton } from "./CurrentProductButton"
import { ProductButton } from "./ProductButton"

interface ParametersFooterProps {
  item: ComparableProduct
  productId?: string
}

export function ParametersFooter({
  item,
  productId,
}: ParametersFooterProps): ReactElement {
  const {
    flatData: { saleConfigurations, name },
  } = item

  if (!saleConfigurations || saleConfigurations.length === 0) {
    return <></>
  }

  const formilyForm: Formily[] | undefined = get(
    saleConfigurations[0],
    "flatData.customPriceBox.formilyForm"
  )

  if (!isEmpty(formilyForm)) {
    return (
      <FormButton
        form={formilyForm}
        text={_t("marketplace.contactButton")}
        title={_t("marketplace.contactButton")}
        color="primary"
        size="small"
      />
    )
  }

  if (saleConfigurations?.[0].id === productId) {
    return (
      <CurrentProductButton
        name={name}
        saleConfiguration={saleConfigurations[0]}
      />
    )
  }

  return (
    <ProductButton
      productId={productId}
      saleConfiguration={saleConfigurations[0]}
      name={name}
    />
  )
}

type ClickFormButtonHandler = {
  (event: SyntheticEvent, form: { id: string; text?: string }): void
}

interface ParametersFooterWithFormProps {
  item: ComparableProduct
  handleClickFormButton: ClickFormButtonHandler
  productId?: string
}

export function ParametersFooterWithForm({
  item,
  handleClickFormButton,
  productId,
}: ParametersFooterWithFormProps) {
  const {
    flatData: { name, form, formTitle, formButtonText },
  } = item

  if (form) {
    return (
      <Button
        onClick={(event) =>
          handleClickFormButton(event, {
            id: form,
            text: formTitle,
          })
        }
        name={name}
        text={formButtonText}
      />
    )
  }

  return <ParametersFooter productId={productId} item={item} />
}
