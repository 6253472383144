import type { ReactElement, SyntheticEvent } from "react"
import React, { useState } from "react"
import _t from "@core/i18n"
import type { CloudBluePlan } from "@gatsby-plugin-definitions/fragments/CloudBluePlan"
import { getDomainsPoolId } from "@gatsby-plugin-generic-page/helpers/getDomainsPoolId"
import { getUpsellData } from "@gatsby-plugin-generic-page/helpers/getUpsellData"
import type { ParametersTableProps } from "~/components/ParametersTable"
import ParametersTable from "~/components/ParametersTable"
import useCurrentProducts from "~/components/ParametersTable/useCurrentProducts"
import type { ComparableProduct } from "~/fragments/comparableProduct"
import useLinkProperties from "~/hooks/useLinkProperties"
import useProductSettings from "~/hooks/useProductSettings"
import isEmpty from "~/lib/isEmpty"
import url from "~/lib/url"
import type { ComparableProductsPackage } from "~/types/squidex"
import FormModal from "../FormModal"
import { ParametersFooterWithForm } from "./ParametersFooter"

export interface ProductParametersProps
  extends Omit<
    ParametersTableProps,
    "generateHeaderLink" | "handleClickFormButton" | "headerColumns"
  > {
  hasHiddenFooter?: boolean
  packages?: ComparableProductsPackage[]
}

interface FromModalProps {
  id: string
  text?: string
}

export default function ProductParameters({
  productId,
  products,
  packages,
  tableHeaderTitle,
  showOnlyDifferences,
  hasPromoPrice,
  hasRenewalPrice,
  hasHiddenGroupLabel,
  hasHiddenFooter,
  hasTogglePriceType,
  showOnlyTwoColumns,
}: ProductParametersProps): ReactElement<ProductParametersProps> {
  const [form, setForm] = useState<FromModalProps>()
  const [visible, setVisible] = useState<boolean>(false)

  const handleClickFormButton = (event: SyntheticEvent, form) => {
    event.preventDefault()
    setForm(form)
    setVisible(true)
  }

  const handleCloseButtonClick = () => {
    setVisible(false)
  }

  const generateHeaderLink = (
    item: ComparableProduct,
    comparableProducts: ComparableProduct[]
  ) => {
    const {
      flatData: { name, saleConfigurations, form, formTitle, formButtonText },
    } = item

    const isActive =
      saleConfigurations && saleConfigurations.length
        ? saleConfigurations[0].id === productId
        : false

    if (!saleConfigurations || saleConfigurations.length === 0 || form) {
      return {
        link: form
          ? {
              onClick: (event) =>
                handleClickFormButton(event, { id: form, text: formTitle }),
              text: formButtonText,
            }
          : undefined,
        title: name,
        isActive: comparableProducts.length > 1 ? isActive : false,
      }
    }

    let productOptions

    const defaultCloudBluePlan: CloudBluePlan["flatData"] | undefined =
      saleConfigurations[0]?.flatData.defaultCloudBluePlan[0]?.flatData

    if (saleConfigurations[0].id === productId) {
      const config = saleConfigurations[0].flatData.config
      const defaultOptions = config.defaultOptions

      if (defaultCloudBluePlan) {
        defaultOptions.productData = defaultCloudBluePlan
      }
      /* eslint-disable react-hooks/rules-of-hooks */
      productOptions = useProductSettings(saleConfigurations[0].id)
    } else {
      productOptions =
        saleConfigurations[0].flatData.config.defaultOptions || {}
    }

    const continueUrl = url.generateBonusUrl({
      p: defaultCloudBluePlan?.alias,
      ax: getUpsellData(saleConfigurations[0].flatData.upsell),
      u: productOptions.upgrade,
      code: productOptions.code,
      period: productOptions.period,
      domainSearch: !isEmpty(saleConfigurations)
        ? getDomainsPoolId(saleConfigurations[0])
        : undefined,
    })

    /* eslint-disable react-hooks/rules-of-hooks */
    const linkProperties = useLinkProperties(continueUrl)

    return {
      title: name,
      isActive,
      link: url
        ? {
            ...linkProperties,
            text: _t("marketplace.chooseButton"),
            rel: "nofollow",
          }
        : undefined,
    }
  }
  const [currentProducts, headerColumns] = useCurrentProducts(
    products,
    packages,
    showOnlyTwoColumns
  )

  return (
    <>
      <ParametersTable
        products={currentProducts}
        headerColumns={headerColumns}
        productId={productId}
        tableHeaderTitle={tableHeaderTitle}
        generateHeaderLink={generateHeaderLink}
        showOnlyDifferences={showOnlyDifferences}
        hasPromoPrice={hasPromoPrice}
        hasRenewalPrice={hasRenewalPrice}
        hasHiddenGroupLabel={hasHiddenGroupLabel}
        footer={
          hasHiddenFooter
            ? undefined
            : currentProducts.map((item) => (
                <ParametersFooterWithForm
                  key={item.id}
                  item={item}
                  handleClickFormButton={handleClickFormButton}
                  productId={productId}
                />
              ))
        }
        showOnlyTwoColumns={showOnlyTwoColumns}
        hasTogglePriceType={hasTogglePriceType}
      />

      {visible && form ? (
        <FormModal
          id={form.id}
          headingText={form.text}
          onCloseButtonClick={handleCloseButtonClick}
        />
      ) : null}
    </>
  )
}
