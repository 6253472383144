import type { ReactElement } from "react"
import React from "react"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import GridOrg from "@onestore/hel/dist/components/organisms/GridOrg"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import Countdown from "@gatsby-plugin-generic-page/components/Countdown"
import Media from "@gatsby-plugin-generic-page/components/Media"
import type { CounterSectionFlatData } from "@gatsby-plugin-generic-page/types/sectionType"
import type { BackgroundImage } from "~/types/squidex"

export default function CounterSection({
  responsiveImages,
  isReversed,
  counter,
  background,
  sectionSettings,
}: CounterSectionFlatData): ReactElement<CounterSectionFlatData> {
  const backgroundImage: BackgroundImage[] | undefined = background
    ? [
        {
          images: background,
          position: "center",
          isCover: true,
        },
      ]
    : undefined

  if (new Date() > new Date(counter.to)) {
    return <></>
  }

  return (
    <BaseSection {...sectionSettings} backgroundImage={backgroundImage}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        <GridOrg
          gutterSpace={0}
          columns={{ small: 1, medium: 1, large: 2 }}
          isReversed={isReversed}
        >
          <FlexContainerOrg alignItems="center" justifyContent="center">
            <Media media={responsiveImages} />
          </FlexContainerOrg>

          <FlexContainerOrg
            alignItems="center"
            justifyContent="center"
            direction="column"
          >
            <Countdown
              labelTypography="tiny1"
              digitTypography="xlarge"
              {...counter}
            />
          </FlexContainerOrg>
        </GridOrg>
      </BaseContainer>
    </BaseSection>
  )
}
