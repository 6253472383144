/**
 * @Module store/bonus/actions-domain-box
 */
import type { BasketResponse } from "@onestore/api/basket"
import type { DomainCheck } from "@onestore/api/domainSearch"
import type { BasketPatchItem, PlanId } from "@onestore/api/types"
import type { BasketStateItem } from "@onestore/onestore-store-common"
import { itemsBasketPrice } from "@onestore/onestore-store-common/api/operations"
import { HTTP_STATUS } from "@onestore/onestore-store-common/http"
import { getDomainPool } from "@gatsby-plugin-bonus/lib/definitions-pools"
import type { DomainPriceData } from "~/hooks/useCloudBlueDomainsQuery"
import api from "~/lib/api"
import { getPopularPoolId } from "~/lib/config"
import { validatePhrase } from "~/lib/domain"
import type { AppState, AppDispatch } from "~/store/reducer"
import type { BonusThunkAction } from "./actions"
import type { BonusItemProduct } from "./actions-bonus"
import { addBonusItemToBasket } from "./actions-bonus"
import { getProduct, getUpsellProductFromBasket } from "./selectors"

export enum BonusDomainsActionType {
  BONUS_UPSELL_DOMAINS_VALIDATION_FAILURE = "@bonus/BONUS_UPSELL_DOMAINS_VALIDATION_FAILURE",
  BONUS_UPSELL_DOMAINS_VALIDATION_SUCCESS = "@bonus/BONUS_UPSELL_DOMAINS_VALIDATION_SUCCESS",

  BONUS_UPSELL_DOMAINS_SEARCH_REQUEST = "@bonus/BONUS_UPSELL_DOMAINS_SEARCH_REQUEST",
  BONUS_UPSELL_DOMAINS_SEARCH_SUCCESS = "@bonus/BONUS_UPSELL_DOMAINS_SEARCH_SUCCESS",
  BONUS_UPSELL_DOMAINS_SEARCH_FAILURE = "@bonus/BONUS_UPSELL_DOMAINS_SEARCH_FAILURE",

  BONUS_UPSELL_DOMAINS_ADD_TO_BASKET_REQUEST = "@bonus/BONUS_UPSELL_DOMAINS_ADD_TO_BASKET_REQUEST",
  BONUS_UPSELL_DOMAINS_ADD_TO_BASKET_SUCCESS = "@bonus/BONUS_UPSELL_DOMAINS_ADD_TO_BASKET_SUCCESS",
  BONUS_UPSELL_DOMAINS_ADD_TO_BASKET_FAILURE = "@bonus/BONUS_UPSELL_DOMAINS_ADD_TO_BASKET_FAILURE",
}

interface BonusUpsellDomainsValidationFailureAction {
  type: BonusDomainsActionType.BONUS_UPSELL_DOMAINS_VALIDATION_FAILURE
}
interface BonusUpsellDomainsValidationSuccessAction {
  type: BonusDomainsActionType.BONUS_UPSELL_DOMAINS_VALIDATION_SUCCESS
}

interface BonusUpsellDomainsSearchRequestAction {
  type: BonusDomainsActionType.BONUS_UPSELL_DOMAINS_SEARCH_REQUEST
  domains: DomainCheck.Result[]
}
interface BonusUpsellDomainsSearchSuccessAction {
  type: BonusDomainsActionType.BONUS_UPSELL_DOMAINS_SEARCH_SUCCESS
  domains: DomainCheck.Result[]
}
interface BonusUpsellDomainsSearchFailureAction {
  type: BonusDomainsActionType.BONUS_UPSELL_DOMAINS_SEARCH_FAILURE
}

interface BonusUpsellDomainsAddToBasketRequestAction {
  type: BonusDomainsActionType.BONUS_UPSELL_DOMAINS_ADD_TO_BASKET_REQUEST
}
interface BonusUpsellDomainsAddToBasketSuccessAction {
  type: BonusDomainsActionType.BONUS_UPSELL_DOMAINS_ADD_TO_BASKET_SUCCESS
}
interface BonusUpsellDomainsAddToBasketFailureAction {
  type: BonusDomainsActionType.BONUS_UPSELL_DOMAINS_ADD_TO_BASKET_FAILURE
}

export type BonusDomainBoxActions =
  | BonusUpsellDomainsValidationFailureAction
  | BonusUpsellDomainsValidationSuccessAction
  | BonusUpsellDomainsSearchRequestAction
  | BonusUpsellDomainsSearchSuccessAction
  | BonusUpsellDomainsSearchFailureAction
  | BonusUpsellDomainsAddToBasketRequestAction
  | BonusUpsellDomainsAddToBasketSuccessAction
  | BonusUpsellDomainsAddToBasketFailureAction

export interface DomainBoxUrlParams {
  pool: number
  extensions: string
  limit: number
}

function getDomainBoxInitialState(
  phrase: DomainCheck.Phrase,
  parameters: DomainBoxUrlParams,
  domainData: DomainPriceData[]
) {
  const { pool, extensions, limit } = parameters

  const extensionsList = extensions ? extensions.split("|") : []
  const domains: DomainCheck.Result[] = []

  if (extensionsList && extensionsList.length) {
    extensionsList.forEach((extension) => {
      const plan = domainData.find(
        (domain) => domain.flatData.extension === extension
      )

      if (plan) {
        domains.push({
          extension,
          plan_id: plan.flatData.id,
          status: "Unknown",
          name: "",
          period: null,
          fqdn: "",
        })
      }
    })
  } else if (pool) {
    const poolData = getDomainPool(pool) || getDomainPool(getPopularPoolId())

    if (poolData) {
      poolData.plans.forEach((item) => {
        domains.push({
          extension: item.name,
          plan_id: item.id,
          status: "Unknown",
          name: "",
          fqdn: "",
          period: null,
        })
      })
    }
  }

  const validatedLimit = Math.abs(limit || 8)

  return domains.slice(0, validatedLimit > 30 ? 30 : validatedLimit)
}

async function getUpsellDomainsPrices(
  state: AppState,
  domains: DomainCheck.Result[],
  name: string
) {
  const basketProduct = getUpsellProductFromBasket(state) as BasketStateItem
  const basketProductChildren: BasketPatchItem[] = Object.values(
    basketProduct?.children || []
  ).map(
    (child): BasketPatchItem => ({
      plan: child.plan_id,
      quantity: 1,
      planPeriod: child.plan_period_id,
      parameters: Array.isArray(child.parameters) ? {} : child.parameters,
    })
  )

  const pricesQueries = domains.map((domain) => {
    const children: BasketPatchItem[] = [
      ...basketProductChildren,
      {
        plan: domain.plan_id as number,
        quantity: 1,
        parameters: {
          domain: `${name}.${domain.extension}`,
        },
      },
    ]

    const item: BasketPatchItem = {
      plan: basketProduct.plan_id,
      planPeriod: basketProduct.plan_period_id,
      quantity: 1,
      children,
    }

    return api.calculateBasket({ items: itemsBasketPrice([item]) })
  })

  const results = await Promise.all(pricesQueries)

  const domainPrices = {}

  results
    .map((result: BasketResponse) => result.items[0].children.pop())
    .forEach((basketItem) => {
      if (
        !basketItem ||
        !("domain" in basketItem.parameters) ||
        basketItem.parameters.domain === null
      ) {
        return
      }
      const {
        parameters: { domain },
        price,
        regular_price,
        tax_rate,
      } = basketItem

      domainPrices[domain] = {
        price,
        regularPrice: regular_price,
        taxRate: tax_rate,
      }
    })

  return Promise.resolve(domainPrices)
}

/**
 * Sprawdzanie dostępności domeny w API oraz pobranie ceny dla danej domeny.
 *
 * @param   {string} phrase    Domena dodawana do koszyka (FQDN)
 * @param   {Object} parameters    Konfiguracja wyszukiwarki domenowej
 *
 * @returns {Promise.<object>}
 */
function searchDomainsForPhrase(
  phrase: DomainCheck.Phrase,
  parameters: DomainBoxUrlParams,
  domainData: DomainPriceData[]
) {
  const domains = getDomainBoxInitialState(phrase, parameters, domainData)

  return async (
    dispatch: AppDispatch<BonusThunkAction>,
    getState: { (): AppState }
  ) => {
    const state = getState()
    dispatch({
      type: BonusDomainsActionType.BONUS_UPSELL_DOMAINS_SEARCH_REQUEST,
      phrase,
      domains,
    } as BonusUpsellDomainsSearchRequestAction)

    const mainDomainResult = await api.checkMainDomain(phrase, [], null)

    if (mainDomainResult.domain.fqdn === mainDomainResult.phrase) {
      domains.unshift(mainDomainResult.domain)
    }

    const {
      domain: { name },
    } = mainDomainResult

    const domainsList = domains.map((domain) => `${name}.${domain.extension}`)
    const domainPrices = await getUpsellDomainsPrices(state, domains, name)

    api
      .checkDomains(domainsList)
      .catch(() => {
        dispatch({
          type: BonusDomainsActionType.BONUS_UPSELL_DOMAINS_SEARCH_FAILURE,
          domains,
        } as BonusUpsellDomainsSearchFailureAction)
      })
      .then(async (result) => {
        if (!result) {
          return
        }
        const resultsMap: Record<string, DomainCheck.Result> = {}
        result.forEach((resultItem) => {
          resultsMap[resultItem.fqdn] = resultItem
        })

        dispatch({
          type: BonusDomainsActionType.BONUS_UPSELL_DOMAINS_SEARCH_SUCCESS,
          domains: domains.map((domain) => {
            const fqdn = `${name}.${domain.extension}`

            return Object.assign({}, domain, domainPrices[fqdn], {
              period: resultsMap[fqdn].period,
              name,
              fqdn,
              status: resultsMap[fqdn].status,
            })
          }),
        } as BonusUpsellDomainsSearchSuccessAction)
      })
  }
}

/**
 * Sprawdzanie dostępności domeny w API z uwzględnieniem lokalnej walidacji wprowadzonej frazy.
 */
export function validateAndSearchDomainsForPhrase(
  phrase: DomainCheck.Phrase,
  parameters: DomainBoxUrlParams,
  domainData: DomainPriceData[]
) {
  return (dispatch: AppDispatch<BonusThunkAction>) => {
    const result = validatePhrase(phrase)

    if (!result.valid) {
      dispatch({
        type: BonusDomainsActionType.BONUS_UPSELL_DOMAINS_VALIDATION_FAILURE,
        phrase,
        errors: result.errors,
      } as BonusUpsellDomainsValidationFailureAction)

      return
    }

    dispatch({
      type: BonusDomainsActionType.BONUS_UPSELL_DOMAINS_VALIDATION_SUCCESS,
    } as BonusUpsellDomainsValidationSuccessAction)

    dispatch(searchDomainsForPhrase(phrase, parameters, domainData))
  }
}

/**
 * Dodanie domeny z upsellu do koszyka.
 *
 * @param   {string} domain    Domena dodawana do koszyka (FQDN)
 * @param   {number} planId    Identyfikator planu domeny dodawanej do koszyka.
 * @param   {string} buttonId  Identyfikator przycisku wywołującego akcję dodawania domeny.
 * @param   {boolean} ignoreHack  Wyłączenie hack'a na domene
 * @returns {Promise.<object>} Ostatnio dodany element koszyka
 */
export function requestAddDomainUpsellToBasket(
  domain: DomainCheck.FQDN,
  planId: PlanId,
  buttonId: string,
  ignoreHack: boolean = true
) {
  return async (
    dispatch: AppDispatch<BonusThunkAction>,
    getState: { (): AppState }
  ) => {
    const upsell: BonusItemProduct = {
      planId: parseInt(`${planId}`, 10),
    }

    dispatch({
      type: BonusDomainsActionType.BONUS_UPSELL_DOMAINS_ADD_TO_BASKET_REQUEST,
      buttonId,
      status: HTTP_STATUS.CONTINUE,
      upsell,
    } as BonusUpsellDomainsAddToBasketRequestAction)

    const product = getProduct(getState())

    try {
      const { addedItem, resources } = await addBonusItemToBasket(
        dispatch,
        getState,
        upsell,
        product,
        null,
        {
          domain,
        },
        ignoreHack
      )

      dispatch({
        type: BonusDomainsActionType.BONUS_UPSELL_DOMAINS_ADD_TO_BASKET_SUCCESS,
        buttonId,
        basketItemId: addedItem.id,
        status: HTTP_STATUS.OK,
        upsell,
        resources,
      } as BonusUpsellDomainsAddToBasketSuccessAction)

      return Promise.resolve(addedItem)
    } catch (error) {
      return dispatch({
        type: BonusDomainsActionType.BONUS_UPSELL_DOMAINS_ADD_TO_BASKET_FAILURE,
        buttonId,
        status: error.code || HTTP_STATUS.BAD_REQUEST,
        error,
        upsell,
      } as BonusUpsellDomainsAddToBasketFailureAction)
    }
  }
}
