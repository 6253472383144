import type { ResolvedOptionsMapping } from "@gatsby-plugin-definitions/lib/sale-configuration"
import type { SaleConfiguration as SaleConfigurationType } from "~/fragments/saleConfigurations"

export default function findProductOptions(
  currentPlanSettings: ResolvedOptionsMapping | null,
  saleConfiguration: SaleConfigurationType
): ResolvedOptionsMapping | undefined | null {
  return (
    currentPlanSettings &&
    saleConfiguration.flatData.config.optionsMapping.find(
      (option) => option.value === currentPlanSettings.value
    )
  )
}
