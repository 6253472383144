import type { ReactElement } from "react"
import React from "react"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import CtaElement from "@gatsby-plugin-generic-page/components/CtaElement"
import FormModalButton from "@gatsby-plugin-generic-page/components/FormModalButton"
import Header from "@gatsby-plugin-generic-page/components/Header"
import type { CtaSectionFlatData } from "@gatsby-plugin-generic-page/fragments/ctaSection"
import isEmpty from "~/lib/isEmpty"

export default function CtaSection({
  header,
  ctaElement,
  horizontalAlign,
  sectionSettings,
  formModalButton,
}: CtaSectionFlatData): ReactElement<CtaSectionFlatData> {
  const align = !isEmpty(horizontalAlign)
    ? horizontalAlign.horizontalAlign
    : "center"
  const alignOnMobile =
    ctaElement && ctaElement?.length > 1 ? "default" : "left"

  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        <AlignContentOrg
          horizontalAlign={{
            small: header?.align?.small.horizontalAlign || alignOnMobile,
            medium: align,
            large: align,
          }}
        >
          {header ? (
            <PushOrg bottomSpace={4}>
              <AlignContentOrg
                horizontalAlign={{
                  small: "left",
                  medium: align,
                  large: align,
                }}
              >
                <Header {...header} />
              </AlignContentOrg>
            </PushOrg>
          ) : null}

          {ctaElement ? <CtaElement ctaElement={ctaElement} /> : null}

          {formModalButton ? (
            <FormModalButton {...formModalButton[0].flatData} />
          ) : null}
        </AlignContentOrg>
      </BaseContainer>
    </BaseSection>
  )
}
