import type { ReactElement } from "react"
import React, { useContext } from "react"
import { typeMatches } from "@onestore-graphql"
import LottieAtm from "@onestore/hel/dist/components/atoms/LottieAtm"
import type { CtaElement } from "@gatsby-plugin-generic-page/fragments/ctaElement"
import { getCtaLottieButtonFlatData } from "@gatsby-plugin-generic-page/helpers/getCtaElementFlatData"
import { replaceLinkValues } from "@gatsby-plugin-generic-page/helpers/linkParser"
import { SquidexComponentType } from "@gatsby-plugin-generic-page/types/componentType"
import SearchUrlParametersContext from "~/context/SearchUrlParametersContext"
import useAnchorProperties from "~/hooks/useAnchorProperties"
import { sendGaEvent } from "~/lib/ga4"
import isEmpty from "~/lib/isEmpty"

interface Props {
  button: CtaElement
}

function LottieButton({ button }: Props): ReactElement | null {
  const {
    actionTypeName,
    lottieIcon,
    isPlayOnHover,
    isAnimationLoop,
    title,
    gaEvent,
    anchorId,
    href,
    openInNewTab,
    pages,
  } = getCtaLottieButtonFlatData(button)
  const anchorProperties = useAnchorProperties(anchorId)
  const currentSearchUrlParameters = useContext(SearchUrlParametersContext)

  if (isEmpty(actionTypeName) || isEmpty(lottieIcon)) {
    return null
  }

  const commonProps = {
    lottiePath: lottieIcon[0].url,
    title,
    isPlayOnHover,
    isAnimationLoop,
  }

  if (
    typeMatches(actionTypeName, SquidexComponentType.SCROLL_TO_SECTION) &&
    !isEmpty(anchorId)
  ) {
    const { onClick, href } = anchorProperties

    return (
      <LottieAtm
        {...commonProps}
        onClick={(event) => {
          onClick(event)
          sendGaEvent(gaEvent)
        }}
        href={href}
      />
    )
  }

  if (
    typeMatches(actionTypeName, SquidexComponentType.EXTERNAL_LINK) &&
    !isEmpty(href)
  ) {
    return (
      <LottieAtm
        {...commonProps}
        onClick={sendGaEvent(gaEvent)}
        href={replaceLinkValues(href, currentSearchUrlParameters)}
        openInNewTab={openInNewTab}
      />
    )
  }

  if (
    typeMatches(actionTypeName, SquidexComponentType.INTERNAL_LINK) &&
    !isEmpty(pages)
  ) {
    return (
      <LottieAtm
        {...commonProps}
        onClick={sendGaEvent(gaEvent)}
        to={pages[0].flatData.url}
      />
    )
  }

  return null
}

export default LottieButton
