import { setAffiliateCookie } from "@onestore/onestore-store-common/affiliate"
import { VERSION } from "./src/index"
import { getAffiliateCookieName } from "./src/lib/config"
import { initConsentManager } from "./src/lib/consentManager"
import { pushLocationChange } from "./src/lib/dataLayer"
import { scrollToAnchor } from "./src/lib/dom"
import { checkTokens } from "./src/lib/init"
import { initMetrics } from "./src/lib/metrics"
import { initOaO, pushPageView } from "./src/lib/oao"
import { initTacos } from "./src/lib/tacos"
import { initTracking } from "./src/lib/tracking"

// These scripts can be triggered multiple times during a single page visit
// If they need to run only once, they should be protected internally
initConsentManager(initTacos, initOaO)
initMetrics()
checkTokens()
initTracking()

setAffiliateCookie(getAffiliateCookieName())

window.onestoreContext = {}
window.dataLayer = window.dataLayer || []
window.__version = VERSION
window.__build = process.env.GATSBY_SITE_BUILD

export { default as wrapRootElement } from "~/wrappers/wrapRootElement"

export const onInitialClientRender = () => {
  const hash = window.location.hash

  if (hash) {
    scrollToAnchor(hash.replace("#", ""))
  }
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  const prevPath = prevLocation ? prevLocation.href : null

  pushLocationChange(location.href, prevPath, location.pathname)
  pushPageView()
}
