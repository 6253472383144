import { useCallback, useMemo, useEffect, useRef, useState } from "react"
import type { Duration } from "date-fns"
import { intervalToDuration } from "date-fns/intervalToDuration"
import { useIsomorphicLayoutEffect } from "./useIsomorphicLayoutEffect"

type Time = Record<"days" | "hours" | "minutes" | "seconds", string>

const useCountdown = (to: string): Time => {
  const now = new Date()
  const end = new Date(to)

  const [diff, setDiff] = useState<Duration | null>(null)

  useIsomorphicLayoutEffect()(() => {
    if (now < end) {
      setDiff(
        intervalToDuration({
          start: now,
          end: end,
        })
      )
    }
  }, [])

  const intervalId = useRef<number | null>()

  const isDiffExist =
    diff &&
    Object.keys(diff).filter((key) => diff[key] === 0).length !==
      Object.keys(diff).length

  const isRunning = useMemo(() => isDiffExist, [isDiffExist])

  const count = useCallback((): void => {
    return setDiff(
      intervalToDuration({
        start: new Date(),
        end: end,
      })
    )
  }, [])

  const cleanUp = () => {
    const currentIntervalId = intervalId.current

    if (!currentIntervalId) {
      return
    }

    clearInterval(currentIntervalId)

    intervalId.current = null
  }

  useEffect(() => {
    if (!isRunning) {
      cleanUp()

      return
    }

    intervalId.current = window.setInterval(count, 1000)

    return cleanUp
  }, [isRunning, count])

  return {
    days: diff && diff.days ? `${diff.days}` : "--",
    hours: diff && diff.hours ? `${diff.hours}` : "--",
    minutes: diff && diff.minutes ? `${diff.minutes}` : "--",
    seconds: diff && diff.seconds ? `${diff.seconds}` : "--",
  }
}

export default useCountdown
