import { isNumber } from "lodash"
import type { BasketStateItem } from "@onestore/onestore-store-common"
import type { BasketViewItem } from "@gatsby-plugin-basket/types"

export function isItemEnabled(item: BasketStateItem | BasketViewItem): boolean {
  return !isNumber(
    (item as BasketStateItem).disabled_reason ||
      (item as BasketViewItem).disabledReason
  )
}
