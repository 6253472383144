import type { ReactElement } from "react"
import React from "react"
import BreakpointVisibilityOrg from "@onestore/hel/dist/components/organisms/BreakpointVisibilityOrg"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import type { SearchSectionFlatData } from "@gatsby-plugin-generic-page/fragments/searchSection"
import DomainSearch from "./components/DomainSearch"

function MainSearchSection({
  text,
  sectionSettings,
}: SearchSectionFlatData): ReactElement<SearchSectionFlatData> {
  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        <PushOrg bottomSpace={0.5} hasNoReset>
          <BreakpointVisibilityOrg from="large">
            <FlexContainerOrg alignItems={!!text ? "flex-start" : "center"}>
              <DomainSearch text={text} />
            </FlexContainerOrg>
          </BreakpointVisibilityOrg>

          <BreakpointVisibilityOrg from="small" to="large">
            <DomainSearch text={text} />
          </BreakpointVisibilityOrg>
        </PushOrg>
      </BaseContainer>
    </BaseSection>
  )
}

export default MainSearchSection
