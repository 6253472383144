import type { ReactElement } from "react"
import React from "react"
import { getDomainsPoolId } from "@gatsby-plugin-generic-page/helpers/getDomainsPoolId"
import { getUpsellData } from "@gatsby-plugin-generic-page/helpers/getUpsellData"
import useCurrentPlanSettings from "@gatsby-plugin-marketplace/hooks/useCurrentPlanSettings"
import findProductOptions from "~/components/ParametersTable/findProductOptions"
import { AddToBasketButton } from "~/components/ProductParameters/AddToBasketButton"
import { Button } from "~/components/ProductParameters/Button"
import type { SaleConfiguration } from "~/fragments/saleConfigurations"
import isEmpty from "~/lib/isEmpty"

interface ProductButtonProps {
  productId?: string
  saleConfiguration: SaleConfiguration
  name: string
}

export function ProductButton({
  productId,
  saleConfiguration,
  name,
}: ProductButtonProps): ReactElement {
  const currentPlanSettings = useCurrentPlanSettings(productId)

  const productOptions =
    findProductOptions(currentPlanSettings, saleConfiguration) ||
    saleConfiguration.flatData.config.defaultOptions ||
    {}

  const defaultCloudBluePlan = saleConfiguration.flatData.defaultCloudBluePlan

  const product =
    productOptions.productData || defaultCloudBluePlan[0]?.flatData

  if (saleConfiguration.flatData.skipBonusScreen) {
    return (
      <AddToBasketButton
        product={product}
        name={name}
        saleConfigurationId={saleConfiguration.id}
        isDisabled={isEmpty(product)}
      />
    )
  }

  return (
    <Button
      name={name}
      bonus={{
        p: product.alias,
        ax: getUpsellData(saleConfiguration.flatData.upsell),
        u: productOptions.upgrade,
        code: productOptions.code,
        period: productOptions.period,
        domainSearch: getDomainsPoolId(saleConfiguration),
      }}
      isDisabled={isEmpty(product)}
    />
  )
}
