import type { ReactElement } from "react"
import React from "react"
import LoaderAtm from "@onestore/hel/dist/components/atoms/LoaderAtm"
import PlaceholderAtm from "@onestore/hel/dist/components/atoms/PlaceholderAtm"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import DomainSearchResultBoxOrg from "@onestore/hel/dist/components/organisms/DomainSearchResultBoxOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import ConditionalWrapper from "@onestore/hel/dist/helpers/ConditionalWrapper"
import useBreakpoint from "@onestore/hel/dist/hooks/useBreakpoint"

export function MainResultLoader(): ReactElement {
  const isSmallOnly = useBreakpoint("small", "medium")
  const isMediumUp = !isSmallOnly

  return (
    <DomainSearchResultBoxOrg
      header={<PlaceholderAtm widthSpace={12} heightSpace={1} />}
      image={<LoaderAtm size="small" />}
      main={<PlaceholderAtm widthSpace={24} heightSpace={3} />}
      aside={
        <div>
          <PushOrg bottomSpace={2}>
            <AlignContentOrg horizontalAlign={isMediumUp ? "right" : "center"}>
              <PlaceholderAtm widthSpace={8} heightSpace={1} />
            </AlignContentOrg>
          </PushOrg>

          <ConditionalWrapper
            condition={isSmallOnly}
            wrapper={(contentJsx) => (
              <AlignContentOrg horizontalAlign="center">
                {contentJsx}
              </AlignContentOrg>
            )}
          >
            <LoaderAtm size="tiny" />
          </ConditionalWrapper>
        </div>
      }
    />
  )
}
