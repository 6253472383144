import type { FC, ReactNode, KeyboardEvent, SyntheticEvent } from "react"
import React, { createContext, useContext, useState } from "react"
import _t from "@core/i18n"
import { noop } from "lodash"
import useMainDomainBundleQuery from "@gatsby-plugin-domain-search/hooks/useMainDomainBundleQuery"
import { validateAndCheckMainDomain } from "@gatsby-plugin-domain-search/store/actions"
import { scrollToAnchor } from "~/lib/dom"
import { useAppDispatch } from "~/store/hooks"

interface HeaderSearchInputContextProps {
  hasMobileSearchInput: boolean
  setMobileSearchVisible: (isVisible: boolean) => void
  onChange: (event: SyntheticEvent<HTMLInputElement>) => void
  onKeyPress: (event: KeyboardEvent<HTMLInputElement>) => void
  onSubmit: () => void
  setInputValue: (value: string) => void
  inputValue: string
  placeholder: string
  resetInputValue: () => void
}

const HeaderSearchInputContext = createContext<HeaderSearchInputContextProps>({
  hasMobileSearchInput: false,
  setMobileSearchVisible: noop,
  onChange: noop,
  onKeyPress: noop,
  onSubmit: noop,
  setInputValue: noop,
  resetInputValue: noop,
  inputValue: "",
  placeholder: "",
})

interface HeaderSearchInputContextProviderProps {
  children: ReactNode
}

export const HeaderSearchInputContextProvider: FC<
  HeaderSearchInputContextProviderProps
> = ({ children }: HeaderSearchInputContextProviderProps) => {
  const dispatch = useAppDispatch()
  const { extensions } = useMainDomainBundleQuery()
  const [inputValue, setValue] = useState("")
  const [hasMobileSearchInput, setHasMobileSearchInput] = useState(false)

  const setInputValue = (value: string) => {
    setValue(value)
  }

  const setMobileSearchVisible = (isVisible: boolean) => {
    setHasMobileSearchInput(isVisible)
  }

  const onChange = (event: SyntheticEvent<HTMLInputElement>): void => {
    setValue(event.currentTarget.value)
  }

  const onSubmit = (event?: SyntheticEvent<HTMLInputElement>) => {
    if (event) {
      event.preventDefault()
    }

    if (inputValue) {
      dispatch(validateAndCheckMainDomain(inputValue, inputValue, extensions))
    }
  }

  const onKeyPress = (event: KeyboardEvent) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      event.preventDefault()

      onSubmit()
      scrollToAnchor("SearchInput")
    }
  }

  const resetInputValue = () => setValue("")

  return (
    <HeaderSearchInputContext.Provider
      value={{
        hasMobileSearchInput,
        setMobileSearchVisible,
        onChange,
        onKeyPress,
        onSubmit,
        setInputValue,
        resetInputValue,
        inputValue,
        placeholder: _t("domainSearch.menuInputPlaceholder"),
      }}
    >
      {children}
    </HeaderSearchInputContext.Provider>
  )
}

export const useHeaderSearchInputContext = (): HeaderSearchInputContextProps =>
  useContext<HeaderSearchInputContextProps>(HeaderSearchInputContext)

export default HeaderSearchInputContext
