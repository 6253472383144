import type { ResolvedOptionsMapping } from "@gatsby-plugin-definitions/lib/sale-configuration"
import type { SaleConfigurationState } from "@gatsby-plugin-marketplace/store/selectors"
import { getSaleConfiguration } from "@gatsby-plugin-marketplace/store/selectors"
import { useAppSelector } from "~/store/hooks"
import { getSelectedPlanSettings } from "~/utils"

export default function useCurrentPlanSettings(
  uuid?: string
): ResolvedOptionsMapping | null {
  const saleConfiguration = useAppSelector<SaleConfigurationState | null>(
    (state) => (uuid ? getSaleConfiguration(state, uuid) : null)
  )

  if (!saleConfiguration) {
    return null
  }

  return getSelectedPlanSettings(
    saleConfiguration.optionsMapping,
    saleConfiguration.options,
    saleConfiguration.defaultOptions
  )
}
