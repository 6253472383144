import { useMemo } from "react"
import _t from "@core/i18n"
import { getSelectedPeriodInfo } from "@core/period-info"
import {
  formatPriceObjectCents,
  formatPriceObjectDecimals,
  getCurrencySeparator,
  getCurrencySign,
  hasNoTaxRate,
} from "@core/pricing"
import { PriceType } from "@core/types"
import type { BasicPageSearchData } from "@onestore-graphql/Model"
import type { IFuseOptions } from "fuse.js"
import type { CloudBluePlan } from "@gatsby-plugin-definitions/fragments/CloudBluePlan"
import useFilteredCoreProducts from "@gatsby-plugin-domain-search/hooks/useFilteredCoreProducts"
import { getProductSearchPhrase } from "@gatsby-plugin-domain-search/store/selectors"
import type { ProductCardItem } from "@gatsby-plugin-marketplace/fragments/marketplaceProductCard"
import useFilteredProducts from "@gatsby-plugin-marketplace/hooks/useFilteredProducts"
import isEmpty from "~/lib/isEmpty"
import { useAppSelector } from "~/store/hooks"
import { prepareComponentValues } from "~/utils"

function getProduct(
  product: ProductCardItem
): CloudBluePlan["flatData"] | null {
  const config = product.flatData.saleConfigurations[0].flatData.config
  const defaultCloudBluePlan =
    product.flatData.saleConfigurations[0].flatData.defaultCloudBluePlan

  if (!isEmpty(defaultCloudBluePlan)) {
    return defaultCloudBluePlan[0].flatData
  }

  if (isEmpty(config.defaultOptions.productData)) {
    return null
  }

  return config.defaultOptions.productData
}

function getProductInfoPricing(
  product: ProductCardItem,
  priceType: PriceType = PriceType.NETTO,
  hidePriceType: boolean = false
) {
  if (isEmpty(product.flatData.saleConfigurations)) {
    return null
  }

  const config = product.flatData.saleConfigurations[0].flatData.config

  const productFlatData = getProduct(product)

  if (productFlatData === null) {
    return null
  }

  const periodInfo = getSelectedPeriodInfo(productFlatData.periods, undefined)
  const componentValues = prepareComponentValues(
    config.optionsMapping,
    {},
    config.defaultOptions,
    isEmpty(
      product.flatData.saleConfigurations[0].flatData.defaultCloudBluePlan
    )
      ? undefined
      : product.flatData.saleConfigurations[0].flatData.defaultCloudBluePlan[0]
  )

  if (!componentValues || !periodInfo) {
    return null
  }

  const { priceValues } = componentValues

  const registerPriceParts = [
    formatPriceObjectDecimals(priceValues.price, priceType),
    formatPriceObjectCents(priceValues.price, priceType),
  ]

  const periodName = periodInfo.getPeriodName()

  const priceTypeSuffix = hasNoTaxRate()
    ? _t(`prices.netto`)
    : _t(`prices.${priceType}`)

  const suffix = hidePriceType
    ? `/ ${periodName}`
    : `${priceTypeSuffix}/${periodName}`

  const prefix =
    (config.optionsMapping && config.optionsMapping.length > 1) ||
    product.flatData.hasPricePrefix
  const currency = getCurrencySign()
  const currencySeparator = getCurrencySeparator()

  return {
    integer: registerPriceParts[0],
    fraction: `${currencySeparator}${registerPriceParts[1]}`,
    suffix,
    prefix: prefix ? _t("prices.from") : undefined,
    currency,
  }
}

const fuseOptions: IFuseOptions<ProductCardItem> = {
  shouldSort: true,
  threshold: 0,
  location: 0,
  distance: 100,
  minMatchCharLength: 4,
  ignoreLocation: true,
  keys: [
    {
      name: "flatData.name",
      weight: 10,
    },
    {
      name: "flatData.searchKeywords",
      weight: 7,
    },
    {
      name: "flatData.tags",
      weight: 4,
    },
    {
      name: "flatData.cardDescription",
      weight: 4,
    },
  ],
}

export const coreFuseOptions: IFuseOptions<BasicPageSearchData> = {
  shouldSort: true,
  threshold: 0,
  location: 0,
  distance: 100,
  minMatchCharLength: 3,
  ignoreLocation: true,
  keys: [
    {
      name: "flatData.searchMetadata.flatData.title",
      weight: 10,
    },
    {
      name: "flatData.searchMetadata.flatData.searchKeywords",
      weight: 7,
    },

    {
      name: "flatData.searchMetadata.flatData.description",
      weight: 4,
    },
  ],
}

interface Price {
  integer: string
  fraction: string
  suffix?: string
  prefix?: string
  currency: string
}

export interface DomainSearchResultProduct {
  id: string
  logo: string
  name: string
  description?: string
  priority: number
  oldPrice?: string
  url: string
  price?: Price
}

function mapSearchResult(
  product: ProductCardItem | BasicPageSearchData
): DomainSearchResultProduct | null {
  if ("searchMetadata" in product.flatData) {
    const searchMetadata = product.flatData.searchMetadata[0]

    const logo = searchMetadata.flatData.logo
      ? searchMetadata.flatData.logo[0].url
      : ""

    return {
      id: product.id,
      priority: searchMetadata.flatData.priority || 0,
      name: searchMetadata.flatData.title,
      description: searchMetadata.flatData.description,
      logo,
      url: product.flatData.url,
      oldPrice: undefined,
      price: undefined,
    }
  }

  const productPricing = getProductInfoPricing(
    product as ProductCardItem,
    PriceType.GROSS
  )

  if (!productPricing || !product.flatData.pages) {
    return null
  }

  return {
    id: product.id,
    priority: product.flatData.order || 0,
    name: product.flatData.name,
    description: product.flatData.cardDescription,
    logo: product.flatData.headerLogo ? product.flatData.headerLogo[0].url : "",
    url: product.flatData.pages[0].flatData.url,
    price: productPricing,
  }
}

interface Hook {
  mainProduct: DomainSearchResultProduct | undefined
  moreProducts: DomainSearchResultProduct[]
  phrase: string
  count: number
}

export function useDomainSearchProducts(): Hook {
  const phrase = useAppSelector(getProductSearchPhrase)

  const products = useFilteredProducts(phrase, fuseOptions)
  const coreProducts = useFilteredCoreProducts(phrase, coreFuseOptions)

  return useMemo(() => {
    // wycinamy z winików wyszukiwania 2 znakowe frazy
    if (phrase.length < 3) {
      return {
        phrase: "",
        mainProduct: undefined,
        moreProducts: [],
        count: 0,
      }
    }

    const results = [...coreProducts, ...products]
      .map(mapSearchResult)
      .filter((item): item is DomainSearchResultProduct => item !== null)
      .sort((a, b) => b.priority - a.priority)

    return {
      phrase,
      mainProduct: results[0],
      moreProducts: results.slice(1),
      count: results.length,
    }
  }, [phrase])
}
