import type { ReactElement } from "react"
import React from "react"
import { getDomainsPoolId } from "@gatsby-plugin-generic-page/helpers/getDomainsPoolId"
import { getUpsellData } from "@gatsby-plugin-generic-page/helpers/getUpsellData"
import { AddToBasketButton } from "~/components/ProductParameters/AddToBasketButton"
import { Button } from "~/components/ProductParameters/Button"
import type { SaleConfiguration } from "~/fragments/saleConfigurations"
import useProductSettings from "~/hooks/useProductSettings"

interface CurrentProductButtonProps {
  saleConfiguration: SaleConfiguration
  name: string
}

export function CurrentProductButton({
  saleConfiguration,
  name,
}: CurrentProductButtonProps): ReactElement {
  const productOptions = useProductSettings(saleConfiguration.id)
  const product =
    productOptions.productData ||
    saleConfiguration.flatData.defaultCloudBluePlan[0].flatData

  if (saleConfiguration.flatData.skipBonusScreen) {
    return (
      <AddToBasketButton
        product={product}
        name={name}
        saleConfigurationId={saleConfiguration.id}
      />
    )
  }

  return (
    <Button
      name={name}
      bonus={{
        p: product.alias,
        ax: getUpsellData(saleConfiguration.flatData.upsell),
        u: productOptions.upgrade,
        code: productOptions.code,
        period: productOptions.period,
        domainSearch: getDomainsPoolId(saleConfiguration),
      }}
    />
  )
}
