import type { ReactElement } from "react"
import React from "react"
import GridOrg from "@onestore/hel/dist/components/organisms/GridOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import useBreakpoint from "@onestore/hel/dist/hooks/useBreakpoint"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import CtaElement from "@gatsby-plugin-generic-page/components/CtaElement"
import Forms from "@gatsby-plugin-generic-page/components/Forms"
import Header from "@gatsby-plugin-generic-page/components/Header"
import type { ContentWithFormSectionFlatData } from "@gatsby-plugin-generic-page/fragments/contentWithFormSection"

function ContentWithFormSection({
  header,
  ctaElement,
  form,
  isReversed,
  isReversedOnMobile,
  sectionSettings,
}: ContentWithFormSectionFlatData): ReactElement<ContentWithFormSectionFlatData> {
  const isSmallOnly = useBreakpoint("small", "medium")

  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        <GridOrg
          columns={{ small: 1, medium: 2, large: 2 }}
          gutterSpace={{ small: 6, medium: 6, large: 16.5 }}
          isReversed={isSmallOnly ? isReversedOnMobile : isReversed}
        >
          <div>
            <Header {...header} />
            {ctaElement ? (
              <PushOrg topSpace={3}>
                <CtaElement ctaElement={ctaElement} />
              </PushOrg>
            ) : null}
          </div>

          <Forms forms={form} />
        </GridOrg>
      </BaseContainer>
    </BaseSection>
  )
}

export default ContentWithFormSection
