import type { ReactElement, ReactNode } from "react"
import React from "react"
import _t from "@core/i18n"
import type { HeaderProps } from "@onestore/hel/dist/components/molecules/TableMol/Header"
import type { ComparableProduct } from "~/fragments/comparableProduct"
import TableMolWrapper from "../TableMolWrapper"
import useContent from "./useContent"
import useHeader from "./useHeader"
import useProductParameters from "./useProductParameters"
import useProductPrices from "./useProductPrices"

export interface ParametersTableProps {
  products: ComparableProduct[]
  headerColumns: HeaderProps["columns"]
  tableHeaderTitle?: string
  productId?: string
  generateHeaderLink: {
    (item: ComparableProduct, comparableProducts: ComparableProduct[]): any
  }
  showOnlyDifferences?: boolean
  hasPromoPrice?: boolean
  hasRenewalPrice?: boolean
  hasHiddenGroupLabel?: boolean
  footer?: ReactNode
  showOnlyTwoColumns?: boolean
  hasHiddenProductPrice?: boolean
  hasHiddenParametersName?: boolean
  hasTogglePriceType?: boolean
}

export default function ParametersTable({
  products,
  headerColumns,
  generateHeaderLink,
  tableHeaderTitle,
  productId,
  showOnlyDifferences,
  hasPromoPrice,
  hasRenewalPrice,
  hasHiddenGroupLabel,
  footer,
  showOnlyTwoColumns,
  hasHiddenProductPrice,
  hasHiddenParametersName,
  hasTogglePriceType,
}: ParametersTableProps): ReactElement<ParametersTableProps> | null {
  const parameters = useProductParameters(products, hasHiddenParametersName)

  const productPrices = useProductPrices(
    products,
    productId,
    hasPromoPrice,
    hasRenewalPrice,
    hasHiddenProductPrice
  )

  const header = useHeader(
    products,
    tableHeaderTitle,
    headerColumns,
    generateHeaderLink
  )

  const content = useContent(
    [productPrices, ...(parameters || [])],
    showOnlyDifferences,
    hasHiddenGroupLabel,
    showOnlyTwoColumns
  )

  if (!parameters) {
    return null
  }

  let headerUpdate = header
  let contentUpdate = content

  if (showOnlyTwoColumns) {
    contentUpdate = content.map((items) => {
      const rows = items.rows.map((row) => {
        return {
          ...row,
          values: row.values.slice(0, 2),
        }
      })

      return {
        ...items,
        rows,
      }
    })

    headerUpdate = {
      ...header,
      columns: header.columns.slice(0, 2),
    }
  }

  return (
    <TableMolWrapper
      header={headerUpdate}
      content={contentUpdate}
      showMoreDetails={{
        text: _t("showMore"),
        title: _t("showMore"),
      }}
      footer={footer}
    />
  )
}
