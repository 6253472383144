import type { CustomTextColors } from "@gatsby-plugin-generic-page/fragments/customColor"
import type {
  CustomBackgroundColor,
  SquidexMarkdownEmbeddedContent,
} from "~/types/squidex"

export interface DataProps {
  name: string
  content: SquidexMarkdownEmbeddedContent
  textColor: keyof typeof CustomTextColors
  backgroundColor?: CustomBackgroundColor
  backgroundType?: string
  onCloseClick?: () => void
  hasNoCloseButton?: boolean
  image?: {
    src: string
    retinaSrc?: string
  }
  linkData?: {
    title: string
    href?: string
    to?: string
  }
  modalData?: {
    title: string
    modalText: string
  }
}

export interface BaseBarProps {
  data: DataProps
  type: string
}

export interface NotificationBarProps {
  location: "productPage" | "checkout"
}

export enum BarType {
  INFO = "komunikat",
  PROMOTION = "promocja",
  UNAVAILABLE = "niedostępność",
}
