import { configureStore } from "@reduxjs/toolkit"
import thunk from "redux-thunk"
import domainHookMiddleware from "@gatsby-plugin-basket/middleware/domainHookMiddleware"
import basketSubscriber from "@gatsby-plugin-basket/store/subscriber"
import externalAxDomainEventsMiddleware from "@gatsby-plugin-bonus/store/middleware/externalEvents"
import externalPmailEventsMiddleware from "@gatsby-plugin-bonus-pmail/store/middleware/externalEvents"
import externalEventsMiddleware from "@gatsby-plugin-checkout/store/middleware/externalEvents"
import externalDomainEventsMiddleware from "@gatsby-plugin-domain-search/store/middleware/externalEvents"
import { domainSearchUrlHandler } from "@gatsby-plugin-domain-search/store/subscriber"
import isServer from "~/lib/isServer"
import { isDebugEnabled } from "~/lib/log"
import googleAnalyticsMiddleware from "~/middleware/googleAnalyticsMiddleware"
import trackingMiddleware from "~/middleware/trackingMiddleware"
import { updateApiVersionFromRequest } from "~/store/core/actions"
import rootReducer from "./reducer"

const middleware = [
  externalEventsMiddleware,
  externalDomainEventsMiddleware,
  externalAxDomainEventsMiddleware,
  externalPmailEventsMiddleware,
  trackingMiddleware,
  domainHookMiddleware,
  googleAnalyticsMiddleware,
  thunk,
]

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middleware),
  devTools: isDebugEnabled(),
})

store.subscribe(domainSearchUrlHandler(store))

basketSubscriber(store)

if (!isServer()) {
  window.document.addEventListener(
    "api_version_changed",
    (event: CustomEvent) => {
      store.dispatch(updateApiVersionFromRequest(event.detail.version))
    }
  )
}
