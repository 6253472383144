import type { ReactElement, ReactNode } from "react"
import React, { useContext } from "react"
import styled, { css } from "styled-components"
import SectionSpacingValueSpaceContext from "@onestore/hel/dist/contexts/SectionSpacingValueSpaceContext"
import breakpoint from "@onestore/hel/dist/utils/breakpoint"
import space from "@onestore/hel/dist/utils/space"

interface Props {
  children: ReactNode
}

const topIndentValueSpace = 10
const topIndentValueSpaceMobile = 6

const Wrapper = styled.div<{ $sectionTopSpacingValueSpace: number }>(
  ({ $sectionTopSpacingValueSpace }) => css`
    margin-bottom: ${space(5)};
    margin-top: ${space(
      -1 * ($sectionTopSpacingValueSpace + topIndentValueSpace)
    )};

    ${breakpoint("medium")} {
      margin-bottom: ${space(6)};
    }

    ${breakpoint("small", "medium")} {
      margin-top: ${space(
        -1 * ($sectionTopSpacingValueSpace + topIndentValueSpaceMobile)
      )};
    }
  `
)

function SubmitInputWrapper({ children }: Props): ReactElement<Props> {
  const sectionTopSpacingValueSpace = useContext(
    SectionSpacingValueSpaceContext
  ).top

  return (
    <Wrapper $sectionTopSpacingValueSpace={sectionTopSpacingValueSpace}>
      {children}
    </Wrapper>
  )
}

export default SubmitInputWrapper
