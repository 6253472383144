import type { ReactElement } from "react"
import React from "react"
import SavingsIcon from "@onestore-icons/savings-icon.svg"
import MessageAccordionMol from "@onestore/hel/dist/components/molecules/MessageAccordionMol"
import BreakpointVisibilityOrg from "@onestore/hel/dist/components/organisms/BreakpointVisibilityOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import type { Color } from "@onestore/hel/dist/components/organisms/SurfaceOrg/SurfaceOrg.types"
import MessageBox from "@gatsby-plugin-domain-search/components/MessageBox"
import type { DomainHookContent } from "@gatsby-plugin-domain-search/hooks/useDomainHookContentQuery"
import MarkdownText from "@gatsby-plugin-generic-page/components/Markdown/Text"
import MarkdownTitle from "@gatsby-plugin-generic-page/components/Markdown/Title"

export interface Props extends DomainHookContent {
  backgroundColor: Color
  backgroundColorEmphasis?: number
  mobileBackgroundColor?: Color
}
function ResponsiveMessageBox({
  desktopPrefix,
  mobileTitle,
  text,
  backgroundColor,
  backgroundColorEmphasis,
  mobileBackgroundColor,
}: Props): ReactElement<Props> {
  return (
    <>
      <BreakpointVisibilityOrg from="large">
        <MessageBox
          prefix={desktopPrefix}
          text={text}
          backgroundColor={backgroundColor}
          backgroundColorEmphasis={backgroundColorEmphasis}
        />
      </BreakpointVisibilityOrg>

      <BreakpointVisibilityOrg from="small" to="large">
        <MessageAccordionMol
          title={
            <MarkdownTitle
              typography="tiny1"
              boldTypography="tiny2"
              emphasis="medium"
              hasNotPushSpace
            >
              {mobileTitle}
            </MarkdownTitle>
          }
          content={
            <PushOrg topSpace={1} hasNoReset>
              <MarkdownText
                typography={{ bold: "nano2", default: "nano1" }}
                emphasis="medium"
                hasOverridedSmallTypography
              >
                {text}
              </MarkdownText>
            </PushOrg>
          }
          pushSpace={{
            top: 2,
            right: 1.5,
            bottom: 1.5,
            left: 1.5,
          }}
          background={mobileBackgroundColor || backgroundColor}
          icon={<SavingsIcon />}
        />
      </BreakpointVisibilityOrg>
    </>
  )
}

export default ResponsiveMessageBox
