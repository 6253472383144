export function safeFind<T>(
  items: T[],
  predicate: (value: T) => boolean,
  message: string = "Value was not found in collection."
): T {
  const value = items.find(predicate)
  if (value === undefined || value === null) {
    throw new TypeError(message)
  }

  return value
}
