import React from "react"
import { get, find } from "lodash"
import Checkout from "@gatsby-plugin-checkout/layouts/Checkout"
import ExternalScripts from "~/components/ExternalScripts"
import Base from "~/layouts/Base"
import { store } from "~/store"

export function createWrapperWithStore(wrapper) {
  return wrapper(store)
}

export function wrapWithLayout(element, props, ignoredPaths = []) {
  if (find(ignoredPaths, (item) => item === props.location.pathname)) {
    return addExternalScripts(element, props)
  }

  return <Base {...props}>{addExternalScripts(element, props)}</Base>
}

export function addExternalScripts(element, props) {
  const page = get(
    props,
    "data.GraphCmsSquidex.pages.0",
    get(
      props,
      "data.GraphCmsSquidex.productPages.0",
      get(props, "data.GraphCmsSquidex.websites.flatData.pages.0")
    )
  )

  if (!page) {
    return element
  }

  return (
    <>
      <ExternalScripts page={page} />
      {element}
    </>
  )
}

export function wrapWithCheckoutLayout(element, props) {
  // Na podstronach niepowodzenie/dziekujemy CheckoutContext jest zbędny bo działamy na oldToken
  return (
    <Checkout
      skipBasketContext={props.pageContext.skipBasketContext || false}
      {...props}
    >
      {element}
    </Checkout>
  )
}
