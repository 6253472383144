import type { CloudBluePeriod } from "@gatsby-plugin-definitions/fragments/CloudBluePeriod"
import type { DomainCheck } from "./domainSearch"
import type {
  NullableAccountType,
  PeriodName,
  PeriodType,
  ProductAlias,
  TaxRate,
} from "./types"

export interface BasketResponseResource {
  id: number
  resource_id: number
  price: PriceValue
  last_patch_operation_flag: boolean
  alias: string | null
  measure_unit: string
  name: string
  quantity: number
  regular_price: PriceValue
  sku: string
  tax_rate: number
  removable?: boolean
}

export type PriceValue = number

export type BasketParamsResponse = Record<string, BasketParamListItem>

export type BasketLastItem = BasketResponseItem | BasketResponseResource

export declare namespace Basket {
  export type Token = string | null
  export type Status =
    | "none"
    | "pending"
    | "has_user_data"
    | "to_migrate"
    | "active"
    | "archived"
    | "assigned"
    | string
}

export interface PaymentMethod {
  id: number
  name: string
  variant: string | null
  description: string
  subtext?: string
  system_id: string
  use_for_auto_payments: boolean
  selected: boolean
}

export interface PaymentMethodWithLogo extends PaymentMethod {
  logo: string
}

export enum ProductType {
  BUSINESS = "business",
  DOMAIN = "domain",
  HELP = "help",
  HOSTING = "hosting",
  MAIL = "mail",
  OFFICE = "office",
  SECURITY = "security",
  SERVICE = "service",
  SHOP = "shop",
  SSL = "ssl",
  VPS = "vps",
  WEBSITE = "website",
  PMAIL = "pmail",
  RESOURCE = "resource",
  UPGRADE = "upgrade",
  UPSELL = "upsell",
}

export enum DefinitionType {
  PRODUCT = "product",
  DOMAIN = "domain",
  PMAIL = "pmail",
  RESOURCE = "resource",
}

export interface BasketResponseItem {
  name: string
  linked_domain_names?: string[]
  alias: string
  extension: null | string
  id: number
  regular_price: PriceValue
  renewal_price: PriceValue
  period_name: PeriodName
  price: PriceValue
  tax_rate: number
  quantity: number
  measure_unit?: string | null
  type: string
  resources: BasketResponseResource[]
  hidden_resources?: BasketResponseResource[]
  children: BasketResponseItem[]
  parameters: Record<string, string | null> | []
  sku: string
  last_patch_operation_flag: boolean
  plan_id: number
  plan_period_id: number
  upsell: any
  is_trial: boolean
  has_promo_code?: boolean
  definition_type: DefinitionType | string
  is_one_time_fee_item: boolean
  disabled_reason?: null | number
  periods: CloudBluePeriod[]
  description?: string // TODO usunąć w następnej iteracji zmian CBCUPDATE-116
  is_over_promo_limit: boolean
  plan_has_promo_limit: boolean
}

export interface BasketParamResponseItem {
  paramId: string
  description: string
  paramName: string
  required: boolean
  value: string | null
  paramType: string
  paramAlias: string | null
  paramWeight: number
  defaultValue: string
}

export interface BasketParamListItem {
  item_id: number
  plan_id: number
  type: string
  params: Record<string, BasketParamResponseItem>
}

export interface BasketUpsellDomain {
  has_promo_code: boolean
  plan_period_type: PeriodType
  plan_period_duration: number
  tax_rate: TaxRate
  price: PriceValue
  alias: ProductAlias
  type: string
  regular_price: PriceValue
  plan_period_id: number
  parameters: {
    domain: DomainCheck.FQDN
  }
  plan_id: number
}
export interface BasketMessage {
  message: string
  type?: string
  id?: number
}
export interface BasketResponse {
  token: Basket.Token
  status: Basket.Status
  items: BasketResponseItem[]
  total_net_price: PriceValue
  total_vat_value: PriceValue
  handling_net_value: PriceValue
  payment_method: number | null
  payment_methods: PaymentMethod[]
  items_count: number
  savings: PriceValue
  domains: string[]
  domain_upsell: BasketUpsellDomain[]
  pmails: string[]
  order_id: null | number
  order_number: string
  account_type: NullableAccountType
  promo_code: string
  needs_parameters: boolean
  message?: null | string // TODO usunąć po zakonczeniu PFLAG-42, tymczasowe do czasu wdrożenia wszędzie nowego api
  messages?: BasketMessage[] // TODO dać jako wymagane po zakonczeniu PFLAG-42, tymczasowe do czasu wdrożenia wszędzie nowego api
}
