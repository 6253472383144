import { round } from "lodash"
import storeSettings from "../../res/storeSettings.json"
import { parsePeriod } from "../api"
import type { PeriodName, Price, TaxRate, TaxValue } from "../api/types"
import type { FormattedPriceInfo, PriceTypeValues as PriceValue } from "./types"
import { PriceType } from "./types"

const { taxRate, currency, pricePrecision, currencyCode } = storeSettings

export const CURRENCY_SEPARATOR: string = ","
export const TAX_VALUE: TaxValue = taxRate / 100
export const CURRENCY_CODE_ISO: string = currencyCode
export const CURRENCY_CODE: string = currency
export const PRICE_DIVIDER: number = Math.pow(10, pricePrecision)

export const getCurrencySeparator = (): string => CURRENCY_SEPARATOR

export const hasNoTaxRate = (): boolean => getTaxRate() === 0
export const getCurrencyCode = (): string => CURRENCY_CODE_ISO
export const getCurrencySign = (): string => CURRENCY_CODE
export const getTaxValue = (): TaxValue => TAX_VALUE
export const getTaxRate = (): TaxRate => {
  if (taxRate <= 0) {
    return 0
  }

  return taxRate
}

/**
 * Funkcja dodająca podatek do podanej ceny.
 *
 * @param   {number} price Cena do której zostanie dodany podatek
 * @returns {number}
 * @deprecated
 */
export const applyDefaultTax = (price: number): number => {
  return parseInt((price * (1 + TAX_VALUE)).toFixed(0), 10)
}

/**
 * Funkcja zwraca prawdziwą cenę (z uwzględnieniem precyzji) jako float.
 * @param price
 * @returns {number}
 */
export const getPriceAsFloat = (price: number): number => {
  return parseFloat(formatPrice(price, "", "."))
}
/**
 * Wyliczanie procentowej różnicy pomiędzy cenami.
 *
 * @param {Price} regularPriceObject cena bazowa
 * @param {Price} priceObject cena aktualna
 * @param {string} type typ zwracanej ceny (netto,gross)
 * @returns {string}
 */
export const calculateDiscountPercent = (
  regularPriceObject: Price,
  priceObject: Price,
  type: PriceType = PriceType.GROSS
): number | null => {
  const regularPrice = regularPriceObject[type]
  const price = priceObject[type]

  if (regularPrice === 0) {
    return null
  }

  return getDiscountValue(regularPrice, price)
}

/** Zasady wyświetlania procentów promocyjnych
 * https://wiki.home.net.pl/display/Onestore/Ceny
 * DYROMNI-373
 **/
export function getDiscountValue(regularPrice: number, price: number): number {
  const discount = parseInt(
    Math.floor(100 - (100 * price) / regularPrice).toFixed(0),
    10
  )

  return discount
}

/**
 * move to period-info
 * @deprecated
 */
export const calculateLowestPriceDiscountPercent = (
  price: Price,
  lowestPrice: Price,
  type: PriceType = PriceType.GROSS
): number | null => {
  const registerPrice = price[type]
  const lowestPriceValue = lowestPrice[type]

  if (lowestPriceValue === 0) {
    return null
  }

  return getLowestPriceDiscountValue(registerPrice, lowestPriceValue)
}

/**
 * move to period-info
 * @deprecated
 */
export function getLowestPriceDiscountValue(
  price: number,
  lowestPrice: number
): number {
  const discount = parseInt(
    Math.floor(100 - (100 * price) / lowestPrice).toFixed(0),
    10
  )

  return discount
}

/**
 * Zwraca obiekt z danymi na temat ceny produktu, okresu.
 *
 * @returns {{netto: number, gross: number, taxRate: TaxRate, currency: string}} Price Obiekt z danymi na temat ceny.
 * @deprecated
 */
export const getPriceObject = (
  netto: number,
  quantityDivider: number,
  taxRate: number
): Price => {
  return {
    netto,
    gross: applyTaxRate(netto, taxRate),
    taxRate: taxRate / 100,
    currency: CURRENCY_CODE,
  }
}

/**
 * Formatowanie wartości procentowej
 *
 * @param {number} discount wartość procentowa
 * @param {boolean} skipSign wartość określająca czy ma być pominięty znak -
 * @returns {string}
 */
export const formatDiscountPercent = (
  discount: number | null,
  skipSign: boolean = false
): string => {
  if (discount === null) {
    return ""
  }

  if (discount < 0) {
    return `+${discount * -1}%`
  }

  if (skipSign || discount === 0) {
    return `${discount}%`
  }

  return `-${discount}%`
}

/**
 * Wyliczanie procentowej różnicy pomiędzy cenami.
 *
 * @param {Price} regularPriceObject cena bazowa
 * @param {Price} priceObject cena aktualna
 * @param {string} type typ zwracanej ceny (netto,gross)
 * @returns {string}
 */
export const getDiscountPercent = (
  regularPriceObject: Price,
  priceObject: Price,
  type: PriceType = PriceType.GROSS,
  skipSign: boolean = false
): string => {
  const discount = calculateDiscountPercent(
    regularPriceObject,
    priceObject,
    type
  )

  return formatDiscountPercent(discount, skipSign)
}

/**
 * move to period-info
 * @deprecated
 */
export const getLowestPriceDiscountPercent = (
  regularPriceObject: Price,
  priceObject: Price,
  type: PriceType = PriceType.GROSS,
  skipSign: boolean = false
): string => {
  const discount = calculateLowestPriceDiscountPercent(
    regularPriceObject,
    priceObject,
    type
  )

  return formatDiscountPercent(discount, skipSign)
}
/**
 * Formatowanie ceny.
 *
 * @param {Price} priceObject cena aktualna
 * @param {string} type typ zwracanej ceny (netto,gross)
 * @returns {string}
 */
export const formatPriceObject = (
  priceObject: PriceValue,
  type: PriceType = PriceType.GROSS
): string => {
  const price = `${normalizePrice(priceObject[type])}`.replace(
    ".",
    CURRENCY_SEPARATOR
  )

  return `${price} ${CURRENCY_CODE}`
}

/**
 * Formatowanie ceny.
 *
 * @param {Price} priceObject cena aktualna
 * @param {string} type typ zwracanej ceny (netto,gross)
 * @returns {string}
 */
export const formatPriceObjectDecimals = (
  priceObject: PriceValue,
  type: PriceType = PriceType.GROSS
): string => {
  const fullprice = splitPrice(normalizePrice(priceObject[type]))

  return fullprice[0]
}

/**
 * Formatowanie ceny.
 *
 * @param {Price} priceObject cena aktualna
 * @param {string} type typ zwracanej ceny (netto,gross)
 * @returns {string}
 */
export const formatPriceObjectCents = (
  priceObject: PriceValue,
  type: PriceType = PriceType.GROSS
): string => {
  const fullprice = splitPrice(normalizePrice(priceObject[type]))

  return fullprice[1]
}

/**
 * Podzielenie ceny na część całkowitą i część dziesiętną.
 *
 * @param {string} price cena
 * @returns {array} tablica zawierająca częsci ceny.
 */
const splitPrice = (price: string): string[] => {
  return `${round(parseFloat(price), 2).toFixed(2)}`
    .replace(".", CURRENCY_SEPARATOR)
    .split(CURRENCY_SEPARATOR)
}

/**
 * Funkcja dodająca podatek do podanej ceny.
 *
 * @param   {number} price Cena do której zostanie dodany podatek
 * @param   {number} taxRate Stawka podatku VAT która zostanie doliczona
 * @returns {number}
 */
export const applyTaxRate = (price: number, taxRate: TaxRate): number => {
  return parseInt((price * (1 + taxRate / 100)).toFixed(0), 10)
}

/**
 * Funkcja dodająca podatek do podanej ceny.
 */
export const applyTaxRateFloat = (
  price: number,
  taxRate: TaxRate,
  fractionDigits: number = 2
): string => {
  return round(price * (1 + taxRate / 100), fractionDigits)
    .toFixed(fractionDigits)
    .replace(".", getCurrencySeparator())
}

export const formatted = (
  priceObject: PriceValue,
  type: PriceType = PriceType.GROSS
): FormattedPriceInfo => {
  const decimals = formatPriceObjectDecimals(priceObject, type)
  const cents = formatPriceObjectCents(priceObject, type)
  const short = `${decimals}${
    parseInt(cents, 10) > 0 ? `${getCurrencySeparator()}${cents}` : ""
  }`

  return {
    decimals,
    cents,
    formatted: formatPriceObject(priceObject, type),
    full: `${decimals}${getCurrencySeparator()}${cents}`,
    currency: CURRENCY_CODE,
    short,
    priceType: type,
    raw: priceObject[type],
  }
}

/**
 * Bazowa funkcja do normalizacji ceny wyświetlanej na stronach.
 *
 * @param   {number} price    Cena do sformatowania
 * @returns {string}
 */
export const normalizePrice = (price: number): string => {
  return round(price / PRICE_DIVIDER, 2).toFixed(2)
}

/**
 * Bazowa funkcja do formatowania ceny wyświetlanej na stronach.
 *
 * @param   {number} price    Cena do sformatowania
 * @param   {string} currency Symbol waluty
 * @param   {string} separator Separator waluty
 * @returns {string}
 */
export const formatPrice = (
  price: number,
  currency: string = CURRENCY_CODE,
  separator: string = CURRENCY_SEPARATOR
): string => {
  return (
    `${normalizePrice(price)}`.replace(".", separator) +
    (currency ? `\u00A0${currency}` : "")
  )
}

/**
 * Zwraca obiekt z danymi na temat ceny produktu, okresu.
 * @deprecated zostało zastąpione metodami z PeriodInfo
 */
export const getPriceObjectWithTaxRate = (
  netto: number,
  taxRate: number,
  quantityDivider: number = 1
): Price => {
  return {
    netto: round(netto / quantityDivider, 0),
    gross: round(applyTaxRate(netto, taxRate) / quantityDivider, 0),
    taxRate: taxRate,
    currency: CURRENCY_CODE,
  }
}

export const getPeriodPricePerMonth = (
  price: number,
  periodName: PeriodName
): number => {
  const { periodType, periodValue } = parsePeriod(periodName)

  let pricePerMonth
  switch (periodType) {
    case 1:
      pricePerMonth = price * (30 / periodValue)
      break
    case 2:
      pricePerMonth = price / periodValue
      break
    case 3:
      pricePerMonth = price / 12 / periodValue
      break
    default:
      throw new Error(`Nieobsługiwany typ okresu: ${periodType}`)
  }

  return pricePerMonth
}

/**
 * Funkcja zwraca cene z podatkiem sformatowana pod eventy.
 */
export const getGA4Price = (price: number): number => {
  return Number(
    (parseFloat(applyTaxRateFloat(price, getTaxRate())) / 10000).toFixed(2)
  )
}
