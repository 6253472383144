import type { ReactElement } from "react"
import React from "react"
import _t from "@core/i18n"
import Search from "@onestore-icons/search.svg"
import styled from "styled-components"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import BreakpointVisibilityOrg from "@onestore/hel/dist/components/organisms/BreakpointVisibilityOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import BaseMarkdown from "@gatsby-plugin-generic-page/components/Markdown/Base"
import { mainSearchPromoText } from "@gatsby-plugin-generic-page/markdown/mainSearchSection"
import DomainInputField from "~/components/DomainInputField"

export interface DomainSearchProps {
  text?: string
}

const BreakpointVisibilityOrgStyled = styled(BreakpointVisibilityOrg)`
  flex-grow: 1;
`

function DomainSearch({
  text,
}: DomainSearchProps): ReactElement<DomainSearchProps> {
  const domainInputJsx = (
    <>
      <PushOrg bottomUnit={{ small: 20, medium: 12, large: 12 }}>
        <DomainInputField
          infoText={_t("domainSearch.mainDomainSearch.info")}
          submitText={_t("domainSearch.mainDomainSearch.submit")}
          placeholderText={_t("domainSearch.mainDomainSearch.placeholder")}
          icon={Search}
          isFancy={false}
          hasWiderButton
          shadow="nano"
        />
      </PushOrg>

      {text ? (
        <BaseMarkdown
          options={{
            wrapper: ({ children }) => (
              <AlignContentOrg
                horizontalAlign={{
                  small: "center",
                  medium: "left",
                  large: "left",
                }}
              >
                <TextAtm typography="xsmall1">{children}</TextAtm>
              </AlignContentOrg>
            ),
            forceWrapper: true,
            overrides: mainSearchPromoText,
          }}
        >
          {text}
        </BaseMarkdown>
      ) : null}
    </>
  )

  return (
    <>
      <BreakpointVisibilityOrgStyled from="large">
        {domainInputJsx}
      </BreakpointVisibilityOrgStyled>

      <BreakpointVisibilityOrg from="small" to="large">
        <PushOrg bottomSpace={3}>{domainInputJsx}</PushOrg>
      </BreakpointVisibilityOrg>
    </>
  )
}

export default DomainSearch
