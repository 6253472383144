import type { Store, ThunkAction } from "@reduxjs/toolkit"
import { first, get, includes, noop } from "lodash"
import type { BasketResponseItem } from "@onestore/api/basket"
import type { DomainCheck } from "@onestore/api/domainSearch"
import type {
  BasketPatchItem,
  CheckMainDomainResponse,
  CheckSuggestionsResponse,
  DomainBundlePricing,
} from "@onestore/api/types"
import {
  addBasketItem,
  addBasketItemChild,
  changePromoCode,
} from "@onestore/onestore-store-common/api/operations"
import {
  HTTP_STATUS,
  isResponseFailed,
} from "@onestore/onestore-store-common/http"
import type { BasketSuccessAction } from "@gatsby-plugin-basket/store/actions"
import { BasketActionSource } from "@gatsby-plugin-basket/store/actions"
import { BasketActions } from "@gatsby-plugin-basket/store/constants"
import { getLastItemsFromBasket } from "@gatsby-plugin-basket/store/utils"
import type { CloudBluePeriod } from "@gatsby-plugin-definitions/fragments/CloudBluePeriod"
import { DOMAIN_SEARCH_ACTIONS } from "@gatsby-plugin-domain-search/store/constants"
import StoreAPI from "~/lib/api"
import { getDomainsPeriodModalIds, getPopularPoolId } from "~/lib/config"
import { validatePhrase } from "~/lib/domain"
import type { MessageThunkAction } from "~/store/messages/actions"
import { addRequestErrorGlobalMessage } from "~/store/messages/actions"
import type { AppDispatch, AppState } from "~/store/reducer"
import type { DomainUrlHash } from "../lib/domains"
import { parseDomainHash } from "../lib/domains"
import type { DomainSearch } from "../types"

export enum ACTIONS {
  SEARCH_MODAL_SHOW = "@domains/SEARCH_MODAL_SHOW",
  SEARCH_MODAL_HIDE = "@domains/SEARCH_MODAL_HIDE",
  CHANGE_ITEM_PERIOD_MODAL_SHOW = "CHANGE_ITEM_PERIOD_MODAL_SHOW",
  CHANGE_ITEM_PERIOD_MODAL_CLOSE = "CHANGE_ITEM_PERIOD_MODAL_CLOSE",
}

export interface DomainSearchChangeItemPeriodModalShowAction {
  type: ACTIONS.CHANGE_ITEM_PERIOD_MODAL_SHOW
  extension: string
  itemId: number
  period?: CloudBluePeriod
}

export interface DomainSearchChangeItemPeriodModalCloseAction {
  type: ACTIONS.CHANGE_ITEM_PERIOD_MODAL_CLOSE
}

export interface DomainSearchModalShowAction {
  type: ACTIONS.SEARCH_MODAL_SHOW
  extension?: DomainCheck.Extension
  title: string
}

export interface DomainSearchModalHideAction {
  type: ACTIONS.SEARCH_MODAL_HIDE
}

export interface DomainSearchValidationFailureAction {
  type: DOMAIN_SEARCH_ACTIONS.VALIDATION_FAILURE
  phrase: DomainCheck.Phrase
  rawPhrase: DomainCheck.RawPhrase
  errors: string[]
}

export interface DomainSearchValidationSuccessAction {
  type: DOMAIN_SEARCH_ACTIONS.VALIDATION_SUCCESS
  rawPhrase: DomainCheck.RawPhrase
}

export interface DomainSearchCheckSuccessAction {
  type: DOMAIN_SEARCH_ACTIONS.CHECK_SUCCESS
  result: {
    phrase: DomainCheck.Phrase
    domain: DomainCheck.Result
    bundle: DomainCheck.Result[]
    bundle_pricing: DomainBundlePricing | null
    alternative: DomainCheck.Result | null
    premium: DomainCheck.Result | null
    available: boolean
    special: DomainCheck.Result | null
    unavailable_extensions: DomainCheck.Extension[]
    valid: boolean
  }
  phrase: DomainCheck.Phrase
  poolId: DomainCheck.PoolId
  extension: DomainCheck.Extension
  unavailable_extensions: DomainCheck.Extension[]
}

export interface DomainSearchCheckFailureAction {
  type: DOMAIN_SEARCH_ACTIONS.CHECK_FAILURE
  phrase: DomainCheck.Phrase
  poolId: DomainCheck.PoolId
  extension: DomainCheck.Extension
}

export interface DomainSearchValidationClearAction {
  type: DOMAIN_SEARCH_ACTIONS.VALIDATION_CLEAR
}

export interface DomainSearchCheckPendingAction {
  type: DOMAIN_SEARCH_ACTIONS.CHECK_PENDING
  phrase: DomainCheck.Phrase
  poolId: DomainCheck.PoolId
  extension: DomainCheck.Extension
}

export interface DomainSearchPoolPendingAction {
  type: DOMAIN_SEARCH_ACTIONS.POOL_PENDING
  phrase: string
  poolId: number
}
export interface DomainSearchPoolFailureAction {
  type: DOMAIN_SEARCH_ACTIONS.POOL_FAILURE
  phrase: string
}

export interface DomainSearchLazySuccessAction {
  type: DOMAIN_SEARCH_ACTIONS.LAZY_SUCCESS
  result: DomainCheck.Result[]
}

export interface DomainSearchLazyPendingAction {
  type: DOMAIN_SEARCH_ACTIONS.LAZY_PENDING
  domains: DomainCheck.FQDN[]
}

export interface DomainSearchLazyFailureAction {
  type: DOMAIN_SEARCH_ACTIONS.LAZY_FAILURE
  domains: DomainCheck.FQDN[]
}
export interface DomainSearchLazyDequeueAction {
  type: DOMAIN_SEARCH_ACTIONS.LAZY_DEQUEUE
  domain: DomainCheck.FQDN
}

export interface DomainSearchLazyEnqueueAction {
  type: DOMAIN_SEARCH_ACTIONS.LAZY_ENQUEUE
  domain: DomainCheck.FQDN
}

export interface DomainSearchPoolSuccessAction {
  type: DOMAIN_SEARCH_ACTIONS.POOL_SUCCESS
  phrase: DomainCheck.Phrase
  result: DomainCheck.Result[]
}

export interface DomainSearchViewEnableAdvancedModeAction {
  type: DOMAIN_SEARCH_ACTIONS.TOGGLE_ADVANCED_MODE
  domains: Record<string, DomainSearch.ResultWithPrice>
}

export interface DomainSearchSuggestedFailureAction {
  type: DOMAIN_SEARCH_ACTIONS.SUGGESTED_FAILURE
  phrase: DomainCheck.Phrase
}

export interface DomainSearchSuggestedPendingAction {
  type: DOMAIN_SEARCH_ACTIONS.SUGGESTED_PENDING
  phrase: DomainCheck.Phrase
}

export interface DomainSearchSuggestedSuccessAction {
  type: DOMAIN_SEARCH_ACTIONS.SUGGESTED_SUCCESS
  result: DomainCheck.Result[]
  phrase
}

export type DomainSearchStore = Store<AppState>

export type DomainSearchActions =
  | DomainSearchModalShowAction
  | DomainSearchModalHideAction
  | DomainSearchChangeItemPeriodModalShowAction
  | DomainSearchChangeItemPeriodModalCloseAction
  | DomainSearchSuggestedSuccessAction
  | DomainSearchSuggestedPendingAction
  | DomainSearchSuggestedFailureAction
  | DomainSearchValidationClearAction
  | DomainSearchLazyEnqueueAction
  | DomainSearchLazyDequeueAction
  | DomainSearchViewEnableAdvancedModeAction
  | DomainSearchValidationFailureAction
  | DomainSearchValidationSuccessAction
  | DomainSearchCheckSuccessAction
  | DomainSearchCheckFailureAction
  | DomainSearchCheckPendingAction
  | DomainSearchPoolPendingAction
  | DomainSearchPoolFailureAction
  | DomainSearchLazySuccessAction
  | DomainSearchLazyFailureAction
  | DomainSearchLazyPendingAction
  | DomainSearchPoolSuccessAction

export type DomainSearchThunkAction = ThunkAction<
  void,
  AppState,
  undefined,
  DomainSearchActions
>

export function createDomainSearchModalShowAction(
  title: string,
  extension?: DomainCheck.Extension
): DomainSearchModalShowAction {
  return {
    type: ACTIONS.SEARCH_MODAL_SHOW,
    title,
    extension,
  }
}

export function createDomainSearchrModalHideAction(): DomainSearchModalHideAction {
  return {
    type: ACTIONS.SEARCH_MODAL_HIDE,
  }
}

export function createDomainSearchChangeItemPeriodModalCloseAction(): DomainSearchChangeItemPeriodModalCloseAction {
  return {
    type: ACTIONS.CHANGE_ITEM_PERIOD_MODAL_CLOSE,
  }
}

export function doShowChangePeriodModal(
  dispatch,
  basketDomainsByIds: BasketResponseItem[]
): void {
  const domains = basketDomainsByIds.filter(
    (item) => item.last_patch_operation_flag
  )

  if (domains.length === 1 && basketDomainsByIds.length === 1) {
    const nextPeriod = basketDomainsByIds[0].periods.find(
      (item) => item.period_name === "2y"
    )

    return dispatch({
      type: ACTIONS.CHANGE_ITEM_PERIOD_MODAL_SHOW,
      extension: basketDomainsByIds[0]?.alias,
      itemId: basketDomainsByIds[0]?.id,
      period: nextPeriod,
    })
  }
}

export function checkPool(phrase: DomainCheck.Phrase, pool: number) {
  return (
    dispatch: AppDispatch<DomainSearchThunkAction>
  ): Promise<DomainCheck.Result[] | void> => {
    dispatch({
      type: DOMAIN_SEARCH_ACTIONS.POOL_PENDING,
      phrase,
      poolId: pool,
    })

    return StoreAPI.checkPool(phrase, pool)
      .catch(() => {
        dispatch({
          type: DOMAIN_SEARCH_ACTIONS.POOL_FAILURE,
          phrase,
        })
      })
      .then((result) => {
        if (result === undefined) {
          return
        }

        dispatch({
          type: DOMAIN_SEARCH_ACTIONS.POOL_SUCCESS,
          phrase,
          result,
        })
      })
  }
}

export function checkSuggestions(
  phrase: string,
  extension: DomainCheck.Extension | null = null
) {
  return (
    dispatch: AppDispatch<DomainSearchThunkAction>
  ): Promise<CheckSuggestionsResponse | void> => {
    dispatch({
      type: DOMAIN_SEARCH_ACTIONS.SUGGESTED_PENDING,
      phrase,
    })

    const result = extension
      ? StoreAPI.checkSuggestionsForExtension(phrase, extension)
      : StoreAPI.checkSuggestions(phrase)

    return result
      .catch(() => {
        dispatch({
          type: DOMAIN_SEARCH_ACTIONS.SUGGESTED_FAILURE,
          phrase,
        })
      })
      .then((searchResult) => {
        if (result === undefined) {
          return
        }
        dispatch({
          type: DOMAIN_SEARCH_ACTIONS.SUGGESTED_SUCCESS,
          result: searchResult,
          phrase,
        })
      })
  }
}

function dispatchMainDomainCheck(
  dispatch: AppDispatch<DomainSearchThunkAction>,
  phrase: DomainCheck.Phrase,
  bundleExtensions: string[],
  poolId: DomainCheck.PoolId | null,
  extension: DomainCheck.Extension | null = null
): Promise<CheckMainDomainResponse | void> {
  let poolExtensions: DomainCheck.Extension[] = []
  let result: Promise<CheckMainDomainResponse | void> = poolId
    ? StoreAPI.checkMainDomainFromPool(
        phrase,
        poolId,
        bundleExtensions,
        extension
      )
    : StoreAPI.checkMainDomain(phrase, bundleExtensions, extension)

  result = result
    .catch(() => {
      dispatch({
        type: DOMAIN_SEARCH_ACTIONS.CHECK_FAILURE,
        phrase,
        poolId,
        extension,
      })
    })
    .then((searchResult) => {
      if (searchResult === undefined) {
        return
      }

      dispatch({
        type: DOMAIN_SEARCH_ACTIONS.CHECK_SUCCESS,
        result: searchResult,
        phrase,
        poolId,
        extension,
      })
    })

  dispatch({
    type: DOMAIN_SEARCH_ACTIONS.CHECK_PENDING,
    phrase,
    poolId,
    extension,
  })

  dispatch(checkPool(phrase, poolId || getPopularPoolId()))

  if (!extension) {
    dispatch(checkSuggestions(phrase, first(poolExtensions)))
  } else {
    dispatch(checkSuggestions(phrase, extension))
  }

  return result
}

export function checkMainDomainRetry(
  phrase: DomainCheck.Phrase,
  bundleExtensions: string[]
) {
  return (
    dispatch: AppDispatch<DomainSearchThunkAction>,
    getState: { (): AppState }
  ) => {
    const state = getState().domainSearch.currentCheck
    dispatchMainDomainCheck(
      dispatch,
      phrase,
      bundleExtensions,
      state.poolId,
      state.extension
    )
  }
}

export function clearDomainValidation() {
  return (dispatch: AppDispatch<DomainSearchThunkAction>): void => {
    dispatch({
      type: DOMAIN_SEARCH_ACTIONS.VALIDATION_CLEAR,
    })
  }
}

function checkMainDomain(
  phrase: DomainCheck.Phrase,
  bundleExtensions: string[],
  poolId: DomainCheck.PoolId | null = null,
  extension: DomainCheck.Extension | null = null
) {
  return (
    dispatch: AppDispatch<DomainSearchThunkAction>,
    getState: { (): AppState }
  ) => {
    if (
      !phrase ||
      (getState().domainSearch.currentCheck.phrase === phrase &&
        !isResponseFailed(getState().domainSearch.currentCheck.request_state))
    ) {
      return Promise.resolve()
    }

    return dispatchMainDomainCheck(
      dispatch,
      phrase,
      bundleExtensions,
      poolId,
      extension
    )
  }
}

export function validateAndCheckMainDomain(
  phrase: DomainCheck.Phrase,
  rawPhrase: DomainCheck.RawPhrase,
  bundleExtensions: string[],
  poolId: DomainCheck.PoolId | null = null,
  extension: DomainCheck.Extension | null = null
) {
  return (dispatch: AppDispatch<DomainSearchThunkAction>): void => {
    dispatch(clearDomainValidation())
    const result = validatePhrase(phrase)

    if (!result.valid) {
      dispatch({
        type: DOMAIN_SEARCH_ACTIONS.VALIDATION_FAILURE,
        phrase,
        errors: result.errors,
        rawPhrase,
      })

      return
    }

    dispatch({
      type: DOMAIN_SEARCH_ACTIONS.VALIDATION_SUCCESS,
      rawPhrase,
    })

    dispatch(checkMainDomain(phrase, bundleExtensions, poolId, extension))
  }
}

export function checkDomainFromHash(
  hash: string,
  rawValue: string | undefined,
  bundleExtensions: string[]
) {
  return (dispatch: AppDispatch<DomainSearchThunkAction>): void => {
    const data: DomainUrlHash = parseDomainHash(hash)

    if (data.d && data.d.length > 0) {
      const pool = data.p ? parseInt(data.p, 10) : null
      dispatch(
        validateAndCheckMainDomain(
          data.d,
          rawValue || data.d,
          bundleExtensions,
          pool,
          data.e
        )
      )
    }
  }
}

function lazyLoad(
  dispatch: AppDispatch<DomainSearchThunkAction>,
  domains: DomainCheck.FQDN[]
) {
  dispatch({
    type: DOMAIN_SEARCH_ACTIONS.LAZY_PENDING,
    domains: domains,
  })

  StoreAPI.checkDomains(domains)
    .catch(() => {
      dispatch({
        type: DOMAIN_SEARCH_ACTIONS.LAZY_FAILURE,
        domains: domains,
      })
    })
    .then((result) => {
      dispatch({
        type: DOMAIN_SEARCH_ACTIONS.LAZY_SUCCESS,
        result,
      })
    })
}

let timer
const checkQueue = new Set<DomainCheck.FQDN>([])

export function lazyLoadQueue(
  domain: DomainCheck.FQDN,
  type: DOMAIN_SEARCH_ACTIONS
) {
  if (type === DOMAIN_SEARCH_ACTIONS.LAZY_ENQUEUE) {
    checkQueue.add(domain)
  } else {
    checkQueue.delete(domain)
  }

  clearTimeout(timer)

  return (dispatch: AppDispatch<DomainSearchThunkAction>) => {
    if (checkQueue.size === 0) {
      return
    }

    timer = setTimeout(() => {
      const domainsToCheck = [...checkQueue.values()]
      checkQueue.clear()
      lazyLoad(dispatch, domainsToCheck)
    }, 200)
  }
}

function addItemsToBasket(
  basketItems: BasketPatchItem[],
  buttonId: string,
  source: BasketActionSource,
  onSuccess: { (result): void } = () => {},
  promoCode: string | null = null,
  headers: Record<string, string>
) {
  return (
    dispatch: AppDispatch<DomainSearchThunkAction> &
      AppDispatch<MessageThunkAction>,
    getState: { (): AppState & AppState }
  ) => {
    dispatch({
      type: BasketActions.BASKET,
      buttonId,
      basketItemsIds: [buttonId],
      source,
    })

    const state = getState()

    const operations = basketItems.map((item) => {
      if (item.parent) {
        return addBasketItemChild(item.parent, item)
      }

      return addBasketItem(item)
    })

    if (promoCode) {
      operations.push(changePromoCode(promoCode))
    }

    return StoreAPI.basketPatch(
      state.basket.token,
      operations,
      source === BasketActionSource.BASKET,
      headers
    )
      .catch((e) => {
        const errorCode = e.form
          ? HTTP_STATUS.BAD_REQUEST
          : get(e, ["error", "code"]) || HTTP_STATUS.INTERNAL_SERVER_ERROR

        const message = e.form
          ? get(e, ["form", "errors"], []).join(",")
          : e.message || ""

        if (e.form && source !== BasketActionSource.TRANSFER) {
          dispatch(addRequestErrorGlobalMessage(message))
        }

        dispatch({
          type: BasketActions.BASKET_ERROR,
          buttonId,
          response_status_code: errorCode,
          message,
          source,
        })

        return Promise.reject(e)
      })
      .then((result) => {
        if (result === undefined) {
          return
        }

        const domainsChangePieriodIds = getDomainsPeriodModalIds()

        if (
          source === BasketActionSource.DOMAINS &&
          domainsChangePieriodIds &&
          domainsChangePieriodIds.length > 0
        ) {
          const basketDomainsByIds = result.items.filter(
            (item) => includes(domainsChangePieriodIds, item.plan_id) && item
          )

          doShowChangePeriodModal(dispatch, basketDomainsByIds)
        }

        dispatch({
          type: BasketActions.BASKET_SUCCESS,
          buttonId,
          result,
          source,
        } as BasketSuccessAction)

        onSuccess(result)

        return Promise.resolve(getLastItemsFromBasket(result))
      })
  }
}

export function addTransferToBasket(
  domain: DomainSearch.DomainFqdnWithPlan,
  authinfo: string,
  buttonId: string,
  onSuccess: { (): void } = noop
) {
  const basketItem: BasketPatchItem = {
    plan: domain.plan,
    ignoreHack: true,
    quantity: 1,
    parameters: {
      domain: domain.fqdn,
      transfer: true,
      authinfo,
    },
  }

  return addItemsToBasket(
    [basketItem],
    buttonId,
    BasketActionSource.TRANSFER,
    onSuccess,
    null,
    {}
  )
}

export function addDomainsToBasket(
  domains: DomainSearch.DomainFqdnWithPlan[],
  childDomains: DomainSearch.DomainFqdnWithPlan[],
  buttonId: string,
  onSuccess = () => {},
  actionSource: BasketActionSource,
  disableApiHook: boolean,
  headers: Record<string, string>
) {
  const basketItems: BasketPatchItem[] = domains.map((domain) => {
    const children =
      Array.isArray(childDomains) && childDomains.length > 0
        ? childDomains.map((domain) => {
            return {
              plan: domain.plan,
              quantity: 1,
              parameters: {
                domain: domain.fqdn,
              },
              ignoreHack: disableApiHook,
            }
          })
        : undefined

    return {
      plan: domain.plan,
      quantity: 1,
      parameters: {
        domain: domain.fqdn,
      },
      ignoreHack: disableApiHook,
      children,
    }
  })

  return addItemsToBasket(
    basketItems,
    buttonId,
    actionSource,
    onSuccess,
    null,
    headers
  )
}

export function resetButtonStatus(buttonId) {
  return {
    type: BasketActions.BASKET_RESET_BUTTON,
    buttonId,
  }
}
