import type { UseQueryResult } from "@tanstack/react-query"
import { useQuery } from "@tanstack/react-query"
import { isEmpty } from "lodash"
import "whatwg-fetch"
import queryString from "query-string"
import { z } from "zod"
import type {
  CompanyData,
  PersonData,
  UserResponse,
  PatchUserResponse,
} from "@onestore/api/account"
import type {
  Basket,
  BasketResponse,
  BasketParamsResponse,
} from "@onestore/api/basket"
import type { DomainCheck } from "@onestore/api/domainSearch"
import {
  CompanyResponse,
  CompanyResponseSchema,
  UserRegistrationResponse,
} from "@onestore/onestore-store-common/api/shapes"
import { captureErrorMessage } from "@onestore/onestore-store-common/debug"
import { getAppVersion, getBuildTimestamp } from "~/lib/config"
import { EmptyTokenException, ResponseError } from "~/lib/exceptions"
import { getMetricsPayload } from "~/lib/metrics"
import Storage, { STORAGE } from "~/lib/storage"
import CookieStorage from "~/lib/storage/CookieStorage"
import LocalStorage from "~/lib/storage/LocalStorage"
import SessionStorage from "~/lib/storage/SessionStorage"
import { errorJSON, parseJSON, sanitizePhrase } from "../../api"
import type {
  CalculateBasketPayload,
  ProductPriceResponse,
  Query,
  JSONResponse,
  NoContentResponse,
  CheckDomainsResponse,
  CheckMainDomainResponse,
  CheckOfficeDomainOwnershipResponse,
  CheckOfficeDomainResponse,
  CheckoutResponse,
  CheckPmailResponse,
  CheckSuggestionsResponse,
  CheckUserPasswordResponse,
  InvoiceOrderApiResponse,
  JSONPatch,
  TermResponse,
  UserDataResponse,
  FetchCalculateProductsPayload,
  FetchCalculateProductsResponse,
} from "../../api/types"
import type { FetchOptions } from "../index"

export const PRODUCT_CALCULATE_CACHE_TTL = 60 * 5

export interface Headers {
  [key: string]: string
}

function checkToken(token: Basket.Token): void {
  if (undefined === token || token === null || token === "") {
    throw new EmptyTokenException()
  }
}

function options(optionValues: FetchOptions = {}): RequestInit {
  const opts: FetchOptions = optionValues
  const headers = {
    "X-Visit-Referrer": document.referrer, // referer klienta - skąd przyszedł
    "X-Page-Referrer": document.location.toString(), // pełen referrer z query stringiem
    "X-Build-Id": `${getAppVersion()}@${getBuildTimestamp()}`,
  }

  if (optionValues.headers) {
    Object.keys(optionValues.headers).forEach((key) => {
      if (optionValues.headers[key]) {
        headers[key] = optionValues.headers[key]
      }
    })
  }

  const cookieInfo = CookieStorage.get(STORAGE.COOKIE_INFO)

  if (cookieInfo) {
    headers["X-Cookie-Consent"] = cookieInfo
  }

  const metricsPayload = getMetricsPayload()

  if (metricsPayload) {
    headers["X-DID"] = metricsPayload
  }

  opts.headers = {
    ...headers,
    [`Accept`]: "application/json",
    [`Content-Type`]: "application/json",
  }

  return opts
}

export function urlWithBundleQueryString(
  url: string,
  extensions: string[]
): string {
  if (extensions.length === 0) {
    return url
  }

  const query = queryString.stringify(
    {
      bundle: extensions,
    },
    { arrayFormat: "bracket" }
  )

  return `${url}?${query}`
}

function getSessionHeaders(config: ApiConfig): Headers {
  if (!config.useSession) {
    return {}
  }

  const sessionId = Storage.getSessionId()

  if (!sessionId) {
    return {}
  }

  return {
    [`session-id`]: sessionId,
  }
}

function checkIfResourceExists(response: Response): Response {
  if (response.status >= 500) {
    throw new ResponseError(response)
  }

  return response
}

export interface ApiConfig {
  useSession?: boolean
}

const defaultConfig: ApiConfig = {
  useSession: false,
}

export function createApi(
  apiHost: string,
  apiConfig: ApiConfig = {},
  responseHandler: { (response: Response): void } | null = null
) {
  const handleResponse = (response: Response): Response => {
    if (responseHandler) {
      responseHandler(response)
    }

    return response
  }

  const apiUrl = (path: string, query: Query = {}) => {
    const url = new URL(path, apiHost)

    Object.entries(query).forEach(([key, value]) => {
      if (value) {
        url.searchParams.append(key, value)
      }
    })

    return url.toString()
  }
  const config: ApiConfig = {
    ...defaultConfig,
    ...apiConfig,
  }

  function fetchJSON<T extends JSONResponse>(
    url: string,
    opts: any = {},
    query: Query = {}
  ): Promise<T> {
    return fetch(apiUrl(url, query), options(opts))
      .then(handleResponse)
      .then((response: Response) => {
        if (response.ok) {
          return response
        }

        if (response.status === 401) {
          Storage.clearSessionId()
        }

        return response.json().then(errorJSON)
      })
  }

  function fetchParsedJSON<T extends JSONResponse>(
    url: string,
    opts: any = {},
    query: Query = {}
  ): Promise<T> {
    return fetchJSON(url, opts, query).then<T>(parseJSON)
  }

  function validateSchema<T extends z.Schema>(
    dto: unknown,
    schema: T
  ): z.infer<T> {
    const { data, success, error } = schema.safeParse(dto)

    if (success) {
      return data
    } else {
      captureErrorMessage(`API Validation Error`, {
        dto: dto,
        error: error.message,
        issues: error.issues,
      })

      throw error
    }
  }

  async function fetchValidated<T extends z.Schema>(
    url: string,
    schema: T,
    params?: RequestInit
  ): Promise<z.infer<T>> {
    const res = await fetch(url, params)

    return validateSchema<T>(await res.json(), schema)
  }

  function fetchToCheckIfResourceExists(
    url: string,
    opts: any = {}
  ): Promise<Response> {
    return fetch(apiUrl(url), options(opts))
      .then(handleResponse)
      .then(checkIfResourceExists)
  }

  const checkMainDomain = (
    phrase: DomainCheck.Phrase,
    bundleExtensions: string[],
    extension: DomainCheck.Extension | null = null
  ): Promise<CheckMainDomainResponse> =>
    fetchParsedJSON(
      urlWithBundleQueryString(
        `/domains/${sanitizePhrase(phrase)}/search/`,
        bundleExtensions
      ),
      {},
      {
        extension,
      }
    )

  const getCompanyInfo = (
    countryCode: string,
    companyNumber: string
  ): Promise<CompanyResponse> =>
    fetchValidated<typeof CompanyResponseSchema>(
      apiUrl(`/companies/${countryCode}/${companyNumber}`),
      CompanyResponseSchema,
      options({})
    )

  const checkMainDomainFromPool = (
    phrase: DomainCheck.Phrase,
    pool: DomainCheck.PoolId,
    bundleExtensions: string[],
    extension: DomainCheck.Extension | null = null
  ): Promise<CheckMainDomainResponse> =>
    fetchParsedJSON(
      urlWithBundleQueryString(
        `/domains/${sanitizePhrase(phrase)}/search/pools/${pool}/`,
        bundleExtensions
      ),
      {},
      {
        extension,
      }
    )

  const checkDomains = (domains: string[]): Promise<CheckDomainsResponse> =>
    fetchParsedJSON(
      `/domains/search`,
      {},
      {
        domains: domains.join(","),
      }
    )

  const checkPool = (
    phrase: DomainCheck.Phrase,
    pool: DomainCheck.PoolId
  ): Promise<DomainCheck.Result[]> =>
    fetchParsedJSON(
      `/domains/${sanitizePhrase(phrase)}/pools/${pool}`,
      {},
      {
        available: "1",
      }
    )

  const checkPMail = (
    phrase: DomainCheck.Phrase
  ): Promise<CheckPmailResponse> =>
    fetchParsedJSON(`/pmails/${sanitizePhrase(phrase)}/check`)

  const checkSuggestions = (
    phrase: DomainCheck.Phrase
  ): Promise<CheckSuggestionsResponse> =>
    fetchParsedJSON(`/domains/${sanitizePhrase(phrase)}/suggestions`)

  const checkSuggestionsForExtension = (
    phrase: DomainCheck.Phrase,
    extension: DomainCheck.Extension
  ): Promise<CheckSuggestionsResponse> =>
    fetchParsedJSON(
      `/domains/${sanitizePhrase(phrase)}/suggestions/extensions/${extension}`
    )

  const loadUserData = (
    token: Basket.Token = ""
  ): Promise<UserDataResponse> => {
    const userData: Promise<UserDataResponse> = fetchParsedJSON(
      "/userdata",
      {
        headers: getSessionHeaders(config),
      },
      {
        allow: "true",
        token,
      }
    ) as Promise<UserDataResponse>

    userData.then((response: UserDataResponse): any => {
      LocalStorage.set(
        STORAGE.BASKET_INFO,
        JSON.stringify({
          [STORAGE.BASKET_INFO_CNT]: response.basket.items_count,
          [STORAGE.BASKET_INFO_NET_PRICE]: response.basket.total_net_price,
          [STORAGE.BASKET_INFO_VAT_VALUE]: response.basket.total_vat_value,
        })
      )
    })

    return userData
  }

  const checkUserExists = (login: string): Promise<Response> =>
    fetchToCheckIfResourceExists(`/users/${login}/check`)

  const registerUser = (
    user: CompanyData | PersonData,
    token: Basket.Token
  ): Promise<UserRegistrationResponse> => {
    checkToken(token)

    return fetchParsedJSON(`/baskets/${token}/users`, {
      method: "POST",
      body: JSON.stringify({
        ...user,
        electronic_invoice: true, // TODO ONESTORE-5907 - wycofać z API to pole po przeniesieniu strefy
      }),
    })
  }

  const basketPatch = (
    token: Basket.Token,
    data: JSONPatch[],
    showDomainUpsell: boolean = false,
    headers: Record<string, string> = {}
  ): Promise<BasketResponse> => {
    checkToken(token)

    return fetchParsedJSON(
      `/baskets/${token}`,
      {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: {
          [`onestore-sig`]: Storage.getSig(),
          ...getSessionHeaders(config),
          ...headers,
        },
      },
      {
        show_domain_upsell: showDomainUpsell ? "1" : "0",
        show_upsell: "0",
      }
    ).then((response: BasketResponse): BasketResponse => {
      LocalStorage.set(
        STORAGE.BASKET_INFO,
        JSON.stringify({
          [STORAGE.BASKET_INFO_CNT]: response.items_count,
          [STORAGE.BASKET_INFO_NET_PRICE]: response.total_net_price,
          [STORAGE.BASKET_INFO_VAT_VALUE]: response.total_vat_value,
        })
      )

      return response
    })
  }

  const basketGet = (
    token: Basket.Token,
    showUpsell: boolean = true
  ): Promise<BasketResponse> => {
    checkToken(token)

    return fetchParsedJSON(
      `/baskets/${token}`,
      {
        headers: getSessionHeaders(config),
      },
      {
        show_domain_upsell: showUpsell ? "1" : "0",
        show_upsell: "0",
      }
    )
  }

  const checkUserPassword = (
    token: Basket.Token,
    login: string,
    password: string,
    rememberMe: boolean = false
  ): Promise<CheckUserPasswordResponse> => {
    checkToken(token)

    return fetchParsedJSON(`/baskets/${token}/user`, {
      method: "PUT",
      body: JSON.stringify({ login, password, remember_me: rememberMe }),
    })
  }

  const checkout = (
    token: Basket.Token,
    params: any
  ): Promise<CheckoutResponse> => {
    checkToken(token)

    return fetchParsedJSON(`/baskets/${token}/checkout`, {
      headers: {
        "onestore-sig": Storage.getSig(),
      },
      method: "PUT",
      body: JSON.stringify(params),
    })
  }

  const getUser = (token: Basket.Token): Promise<UserResponse> => {
    checkToken(token)

    return fetchParsedJSON(`/baskets/${token}/user`, {
      method: "GET",
      headers: {
        [`onestore-sig`]: Storage.getSig(),
        ...getSessionHeaders(config),
      },
    })
  }

  const patchUser = (
    token: Basket.Token,
    data: Partial<CompanyData> | Partial<PersonData>
  ): Promise<PatchUserResponse> => {
    checkToken(token)

    return fetchParsedJSON(`/baskets/${token}/user`, {
      headers: {
        "onestore-sig": Storage.getSig(),
        ...getSessionHeaders(config),
      },
      method: "PATCH",
      body: JSON.stringify(data),
    })
  }

  const getTerms = (token: Basket.Token): Promise<TermResponse> => {
    checkToken(token)

    return fetchParsedJSON(`/baskets/${token}/terms`, {
      method: "GET",
      headers: {
        [`onestore-sig`]: Storage.getSig(),
        ...getSessionHeaders(config),
      },
    })
  }

  /**
   * Pobranie parametrów potrzebnych dla elementów w koszyku
   *
   * @param {string} token Token koszyka.
   *
   * @returns {Promise}
   */
  const getBasketParams = (
    token: Basket.Token
  ): Promise<BasketParamsResponse> =>
    fetchParsedJSON(`/baskets/${token}/params`)

  /**
   * Sprawdzenie dostępności domeny Microsoft.
   *
   * @param {string} token  Token koszyka.
   * @param {string} domain Domena do sprawdzenia.
   *
   * @returns {Promise}
   */
  const checkOfficeDomain = (
    token: Basket.Token,
    domain: string
  ): Promise<CheckOfficeDomainResponse> =>
    fetchParsedJSON(`/baskets/${token}/office/check_domain/${domain}`)

  /**
   * Sprawdzenie statusu konta Microsoft.
   *
   * @param {string} token    Token koszyka.
   * @param {string} domain    Login konta Microsoft.
   *
   * @returns {Promise}
   */
  const checkOfficeDomainOwnership = (
    token: Basket.Token,
    domain: string
  ): Promise<CheckOfficeDomainOwnershipResponse> =>
    fetchParsedJSON(`/baskets/${token}/office/check_ownership`, {
      method: "POST",
      body: JSON.stringify({
        domain,
      }),
    }) as Promise<CheckOfficeDomainOwnershipResponse>

  const saveNps = (
    token: Basket.Token,
    value: number,
    comment: string = ""
  ): Promise<JSONResponse> => {
    checkToken(token)

    return fetchParsedJSON(`/baskets/${token}/nps`, {
      method: "PUT",
      body: JSON.stringify({
        value,
        comment,
      }),
    })
  }

  const calculateBasket = (
    items: CalculateBasketPayload
  ): Promise<BasketResponse> =>
    fetchParsedJSON<BasketResponse>("/baskets/calculate", {
      method: "POST",
      body: JSON.stringify(items),
      headers: getSessionHeaders(config),
    })

  const calculateProduct = (
    items: CalculateBasketPayload
  ): Promise<ProductPriceResponse> =>
    fetchParsedJSON<ProductPriceResponse>("/products/calculate", {
      method: "POST",
      body: JSON.stringify(items),
      headers: getSessionHeaders(config),
    })

  const deleteSession = (
    token: string | null = null
  ): Promise<NoContentResponse> =>
    fetchJSON(
      "/session",
      {
        method: "DELETE",
        headers: getSessionHeaders(config),
      },
      {
        token,
      }
    )

  async function calculateProductWithCache(
    items: CalculateBasketPayload,
    useCache: boolean = true
  ) {
    if (!useCache) {
      return await calculateProduct(items)
    }
    const key = `_api_${JSON.stringify(items)}`

    const data = SessionStorage.getSerialized(key, {})

    if (!isEmpty(data)) {
      return data
    }

    const response = await calculateProduct(items)

    SessionStorage.setSerialized(key, response, PRODUCT_CALCULATE_CACHE_TTL)

    return response
  }

  const invoicesOrder = (
    type: string,
    terms: boolean,
    captcha: string,
    from?: string,
    to?: string,
    accountId?: string,
    email?: string
  ): Promise<InvoiceOrderApiResponse> =>
    fetchParsedJSON<InvoiceOrderApiResponse>("/invoices/order", {
      method: "POST",
      body: JSON.stringify({
        accountId,
        email,
        type,
        terms,
        from,
        to,
        captcha,
      }),
      headers: getSessionHeaders(config),
    })

  const fetchCities = async ({ queryKey }): Promise<Array<string>> => {
    const countryCode = queryKey[1]
    const postcode = queryKey[2]

    if (!countryCode || !postcode) {
      return []
    }

    return fetchParsedJSON(`/postcode/cities/${countryCode}/${postcode}`)
  }

  const useCitiesQuery = (countryCode: string, postcode: string) => {
    return useQuery({
      queryKey: ["cities", countryCode, postcode],
      queryFn: fetchCities,
      staleTime: Infinity,
    })
  }

  const fetchCalculateProducts = ({
    queryKey,
  }): Promise<ProductPriceResponse> => {
    const productsData = queryKey[1]

    if (productsData === null) {
      return new Promise((resolve) => {
        resolve({} as ProductPriceResponse)
      })
    }

    return fetchParsedJSON(`/calculate/multiple`, {
      method: "POST",
      body: JSON.stringify(productsData),
    })
  }

  const useCalculateProductsQuery = (
    productsData: FetchCalculateProductsPayload | null
  ): UseQueryResult<FetchCalculateProductsResponse> => {
    return useQuery({
      queryKey: ["products", productsData],
      queryFn: fetchCalculateProducts,
      staleTime: Infinity,
    })
  }

  return {
    apiUrl,
    calculateBasket,
    saveNps,
    checkOfficeDomainOwnership,
    checkOfficeDomain,
    getBasketParams,
    getTerms,
    patchUser,
    getUser,
    checkout,
    getCompanyInfo,
    registerUser,
    checkMainDomain,
    checkMainDomainFromPool,
    checkUserExists,
    basketPatch,
    basketGet,
    checkUserPassword,
    checkDomains,
    checkPool,
    checkPMail,
    checkSuggestions,
    checkSuggestionsForExtension,
    loadUserData,
    deleteSession,
    invoicesOrder,
    calculateProduct: calculateProductWithCache,
    useCalculateProductsQuery,
    useCitiesQuery,
  }
}
