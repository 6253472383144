/**
 * @Module storeBonusActionsDomainSearchBox
 */
import type { DomainCheck } from "@onestore/api/domainSearch"
import type { CheckDomainsResponse } from "@onestore/api/types"
import { getDomainPool } from "@gatsby-plugin-bonus/lib/definitions-pools"
import type { BundleDomains } from "@gatsby-plugin-bonus/types"
import api from "~/lib/api"
import { getPopularPoolId } from "~/lib/config"
import { validatePhrase } from "~/lib/domain"
import type { AppDispatch } from "~/store/reducer"
import type { BonusThunkAction } from "./actions"

export enum BonusDomainSearchActionType {
  BONUS_DOMAIN_SEARCH_VALIDATION_FAILURE = "@bonus/BONUS_DOMAIN_SEARCH_VALIDATION_FAILURE",
  BONUS_DOMAIN_SEARCH_VALIDATION_SUCCESS = "@bonus/BONUS_DOMAIN_SEARCH_VALIDATION_SUCCESS",

  BONUS_DOMAIN_SEARCH_REQUEST = "@bonus/BONUS_DOMAIN_SEARCH_REQUEST",
  BONUS_DOMAIN_SEARCH_SUCCESS = "@bonus/BONUS_DOMAIN_SEARCH_SEARCH_SUCCESS",
  BONUS_DOMAIN_SEARCH_FAILURE = "@bonus/BONUS_DOMAIN_SEARCH_FAILURE",
  BONUS_DOMAIN_SEARCH_HIDE = "@bonus/BONUS_DOMAIN_SEARCH_HIDE",
}
interface BonusDomainSearchValidationFailureAction {
  type: BonusDomainSearchActionType.BONUS_DOMAIN_SEARCH_VALIDATION_FAILURE
}
interface BonusDomainSearchValidationSuccessAction {
  type: BonusDomainSearchActionType.BONUS_DOMAIN_SEARCH_VALIDATION_SUCCESS
}

interface BonusDomainSearchRequestAction {
  type: BonusDomainSearchActionType.BONUS_DOMAIN_SEARCH_REQUEST
  domains: DomainCheck.Result[]
}

interface BonusDomainSearchSuccessAction {
  nextStep: boolean
  phrase: string
  domains: DomainCheck.Result[]
  bundleDomains: BundleDomains
  type: BonusDomainSearchActionType.BONUS_DOMAIN_SEARCH_SUCCESS
}
interface BonusDomainSearchFailureAction {
  type: BonusDomainSearchActionType.BONUS_DOMAIN_SEARCH_FAILURE
}
interface BonusDomainSearchHideAction {
  hideSearch: boolean
  type: BonusDomainSearchActionType.BONUS_DOMAIN_SEARCH_HIDE
}

export type BonusDomainSearchBoxActions =
  | BonusDomainSearchValidationFailureAction
  | BonusDomainSearchValidationSuccessAction
  | BonusDomainSearchRequestAction
  | BonusDomainSearchSuccessAction
  | BonusDomainSearchFailureAction
  | BonusDomainSearchHideAction

function getDomainBoxInitialState(
  phrase: DomainCheck.Phrase,
  pool: number
): DomainCheck.Result[] {
  const domains: DomainCheck.Result[] = []

  if (pool) {
    const poolData = getDomainPool(pool) || getDomainPool(getPopularPoolId())

    if (poolData) {
      poolData.plans.forEach((item) => {
        domains.push({
          extension: item.name,
          plan_id: item.id,
          status: "Unknown",
          fqdn: `${phrase}.${item.name}`,
          period: null,
          name: phrase,
        })
      })
    }
  }

  return domains.slice(0, 10)
}

/**
 * Sprawdzanie dostępności domeny w API oraz pobranie ceny dla danej domeny.
 *
 * @param   {string} phrase    Domena dodawana do koszyka (FQDN)
 * @param   {string} pool    Pula domen, w której ma być wyszukiwana fraza
 * @param   {string} bundleDomainsExtensions  Pula domen które zawierają się w bundle
 *
 * @returns {Promise.<object>}
 */
function searchDomainsForPhrase(
  phrase: DomainCheck.Phrase,
  pool: number,
  bundleDomainsExtensions?: string[]
) {
  const domains = getDomainBoxInitialState(phrase, pool)

  return async (dispatch: AppDispatch<BonusThunkAction>) => {
    dispatch({
      type: BonusDomainSearchActionType.BONUS_DOMAIN_SEARCH_REQUEST,
      phrase,
      domains,
    } as BonusDomainSearchRequestAction)

    const mainDomainResult = await api.checkMainDomain(
      phrase,
      bundleDomainsExtensions || [],
      null
    )

    if (
      mainDomainResult.domain.fqdn === mainDomainResult.phrase &&
      mainDomainResult.domain.plan_id
    ) {
      domains.unshift(mainDomainResult.domain)
    }

    const {
      domain: { name },
    } = mainDomainResult

    const domainsList = domains.map((domain) => `${name}.${domain.extension}`)

    const bundleDomainsData =
      bundleDomainsExtensions && bundleDomainsExtensions.length > 0
        ? {
            domains: mainDomainResult.bundle,
            prices: mainDomainResult.bundle_pricing,
          }
        : null

    api
      .checkDomains(domainsList)
      .catch(() => {
        dispatch({
          type: BonusDomainSearchActionType.BONUS_DOMAIN_SEARCH_FAILURE,
          domains,
        } as BonusDomainSearchFailureAction)
      })
      .then(async (result: CheckDomainsResponse) => {
        const resultsMap: Record<DomainCheck.FQDN, DomainCheck.Result> = {}
        result.forEach((resultItem) => {
          resultsMap[resultItem.fqdn] = resultItem
        })

        const domainsdata = domains.map((domain) => {
          const fqdn = `${name}.${domain.extension}`
          const period = resultsMap[fqdn].period

          if (period) {
            return {
              ...resultsMap[fqdn],
              name,
              fqdn,
            }
          }

          return {
            ...domain,
            price: 0,
            regularPrice: 0,
            taxRate: 0,
            name,
            fqdn,
            status: resultsMap[fqdn].status,
          }
        })

        dispatch({
          type: BonusDomainSearchActionType.BONUS_DOMAIN_SEARCH_SUCCESS,
          nextStep: true,
          phrase,
          domains: domainsdata,
          bundleDomains: bundleDomainsData,
        } as BonusDomainSearchSuccessAction)
      })
  }
}

/**
 * Sprawdzanie dostępności domeny w API z uwzględnieniem lokalnej walidacji wprowadzonej frazy.
 *
 * @param   {string} phrase    Domena dodawana do koszyka (FQDN)
 * @param   {Object} parameters   Konfiguracja wyszukiwarki
 * @param   {string} bundleDomainsExtensions  Pula domen które zawierają się w bundle
 *
 * @returns {Promise.<object>}
 */
export function validateAndSearchDomainsForPhrase(
  phrase: DomainCheck.Phrase,
  pool: number,
  bundleDomainsExtensions?: string[]
) {
  return (dispatch: AppDispatch<BonusThunkAction>) => {
    const result = validatePhrase(phrase)

    if (!result.valid) {
      dispatch({
        type: BonusDomainSearchActionType.BONUS_DOMAIN_SEARCH_VALIDATION_FAILURE,
        phrase,
        errors: result.errors,
      } as BonusDomainSearchValidationFailureAction)

      return
    }

    dispatch({
      type: BonusDomainSearchActionType.BONUS_DOMAIN_SEARCH_VALIDATION_SUCCESS,
    } as BonusDomainSearchValidationSuccessAction)

    dispatch(searchDomainsForPhrase(phrase, pool, bundleDomainsExtensions))
  }
}

/**
 * Ukrycie ekranu wyszukiwarki
 *
 * @returns {Promise.<boolean>} Ostatnio dodany element koszyka
 */
export function hideSearchDomain() {
  return async (dispatch: AppDispatch<BonusThunkAction>) => {
    dispatch({
      type: BonusDomainSearchActionType.BONUS_DOMAIN_SEARCH_HIDE,
      hideSearch: true,
    } as BonusDomainSearchHideAction)
  }
}
