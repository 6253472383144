import type {
  Account,
  UserContactData,
  CompanyData,
  PersonData,
} from "@onestore/api/account"
import type {
  PriceValue,
  ProductType,
  DefinitionType,
  Basket,
  BasketMessage,
  BasketUpsellDomain,
  BasketParamListItem,
  PaymentMethod,
} from "@onestore/api/basket"
import type { BasketActionSource } from "@gatsby-plugin-basket/store/actions"
import type { CloudBluePeriod } from "@gatsby-plugin-definitions/fragments/CloudBluePeriod"
import type { OnestoreVarsUrl } from "~/lib/url" // TODO: do wyprostowania zależności między paczkami
import type {
  AccountMessage,
  AccountType,
  CurrencyCode,
  CurrencySign,
  NullableAccountType,
  PaymentModalData,
  PeriodName,
  PlanId,
  ProductAlias,
  TaxNumberData,
  TaxRate,
  TaxValue,
  Term,
} from "../api/types"

export type ProductContentName = string

export interface BasketResource {
  last_patch_operation_flag: boolean
}

export interface BasketItem {
  plan: number
  planPeriod: number
  parameters?: Record<string, string | boolean | number>
  quantity: number
  price: PriceValue
  resources: BasketResource[]
  children: BasketItem[]
  last_patch_operation_flag: boolean
  id: number
  sku: string
  name: string
  ignoreHack?: boolean
}

export interface BasketUpdateItem {
  id: number
  plan?: number
  planPeriod?: number
  quantity?: number
}

export interface OnestoreVarsCommon {
  affiliateCookieName: string
  apiUrl: string
  basketUrl: string
  bonusUrl: string
  brand: string
  build: string
  companyNumberCheck: boolean
  currency: CurrencySign
  currencyCode: CurrencyCode
  defaultPriceType: string
  dimensions: string[]
  domainsBonusUrl: string
  domainSearchUrl: string
  failedUrl: string
  finalizeUrl: string
  helpUrl: string
  hostingHackPlanId: number | null
  secondaryHackPlanId: number | null
  alternativeHostingHackPlanId: number | null
  locale: string
  mainPoolId: number
  passwordRecoverUrl: string
  pricePrecision: number
  productsIndex: Record<number, ProductAlias>
  reCaptchaSiteKey: string
  rootCookieDomain: string
  smsCaptchaEnabled: boolean
  smsConfirmationFormat: string
  smsCount: number
  smsErrors: number
  successfulUrl: string
  taxValue: TaxValue
  taxRate: TaxRate
  version: number
  wikiUrl: string
  helpContactUrl: string
  isSessionEnabled: boolean
  isTacosEnabled: boolean
  ceneoOpinionsQuestionnaireDays?: number
  ceneoOpinionsAccountGuid?: string
}

export interface OnestoreVarsPricing {
  currency: CurrencySign
  pricePrecision: number
  taxValue: TaxValue
  taxRate: TaxRate
}

export interface OnestoreVarsI18n {
  locale: string
}

export type OnestoreVars = OnestoreVarsCommon &
  OnestoreVarsI18n &
  OnestoreVarsUrl &
  OnestoreVarsPricing

export interface BonusUrlDefinition {
  content?: ProductContentName
  product: ProductAlias
  upsell?: ProductAlias[]
  bundle?: ProductAlias
  upgrade?: ProductAlias
  period?: PeriodName
  code?: string
  value?: string
  domainSearch?: string
}

export declare namespace HTTP {
  export type Status = 303 | number
  export type Method = "PUT" | "GET" | "POST" | "PATCH" | "DELETE" | "OPTIONS"
}

export interface FetchOptions {
  headers?: any
  method?: HTTP.Method
  body?: string
}

export type AliasParameters = Record<string, string>

export interface AliasProductData {
  alias: string
  template?: string
  parameters?: AliasParameters
}

export interface DomainData {
  extension?: string
  pool?: string
}

export interface BasketStateResource {
  itemId?: { "data-resource": string }
  measure_unit: string
  alias: string | null
  name: string
  resource_id: number
  quantity: number
  sku: string
  price: PriceValue
  regular_price: PriceValue
  id: number
  tax_rate: TaxRate
  last_patch_operation_flag: boolean
  removable?: boolean
}

export interface BasketStateItem {
  parameters: Record<string, string | null> | []
  children: BasketStateItem[]
  linked_domain_names?: string[]
  quantity: number
  sku: string
  last_patch_operation_flag: boolean
  name: string
  measure_unit?: string | null
  price: PriceValue
  id: number
  plan_id: number
  extension: null | string
  plan_period_id: number
  has_promo_code?: boolean
  tax_rate: TaxRate
  regular_price: PriceValue
  resources: BasketStateResource[]
  hidden_resources?: BasketStateResource[]
  is_trial: boolean
  type: ProductType | string
  alias: string
  disabled_reason?: number | null
  period_name: PeriodName
  periods: CloudBluePeriod[] | Record<number, CloudBluePeriod> // Ze wzgledu na błąd w API czasem dostajemy obiekt ONESTORE-5813
  definition_type: DefinitionType | string
  is_over_promo_limit: boolean
  plan_has_promo_limit: boolean
}

export interface BasketProductItem {
  children: BasketProductItem[]
  linked_domain_names?: string[]
  quantity: number
  sku: string
  last_patch_operation_flag?: boolean
  name: string
  measure_unit?: string | null
  price: PriceValue
  has_promo_code?: boolean
  resources: BasketStateResource[]
  hidden_resources?: BasketStateResource[]
  type: ProductType | string
  disabled_reason?: number | null
  periods:
    | CloudBluePeriod[]
    | Record<number, CloudBluePeriod>
    | {
        value: string
        label: string
        labelAx: string
        isSelected?: boolean
        price: { register: string; noPromo: string; hasPromoPrice: boolean }
      }[]
  definition_type?: DefinitionType | string
  itemRemoval?: string
  linkedDomainNames?: string[]
  disabledReason?: null | number
  itemId?: number
  planId?: number
  key?: string
  discount?: number
  regularPrice?: number
  definitionType?: string
  taxRate?: number
  mark?: string
  loading?: boolean
  removable?: boolean
  tooltip?: { labelText: string }
  domainName?: string
  domainExtension?: string
  alias?: string
  period_name: PeriodName
}

export interface OfficeState {
  domain_available?: boolean
  domain_request: HTTP.Status
  domain_name: string

  // status sprawdzenia konta Microsoft Office
  invitation_url?: string
  errors: string[]
  account_request: HTTP.Status
  clicked_on_confirm: boolean
  logged_in: boolean
  accepted: boolean

  account_status?: string
}

export enum ItemRemoval {
  ALLOW = "allow",
  DENY = "deny",
  CONFIRM_BUNDLE_ITEM = "item",
  CONFIRM_HACK_DOMAIN = "hack_domain",
  CONFIRM_HACK_ITEM = "hack_item",
}

export interface BasketState {
  readonly token: Basket.Token
  readonly request_state: HTTP.Status
  readonly vatValue: number
  readonly totalValue: number
  readonly handlingFee: number
  readonly paymentMethod: number | null
  readonly paymentMethods: PaymentMethod[]
  readonly itemsCount: number
  readonly status: Basket.Status
  readonly domains: string[]
  readonly pmails: string[]
  readonly buttons: Record<string, HTTP.Status>
  readonly items_statuses: Record<number, HTTP.Status>
  readonly items: BasketStateItem[]
  readonly savings: PriceValue
  readonly promo_code: undefined | string
  readonly orderId: null | number
  readonly orderNumber: null | string // Tak to prawda pole orderNumber to string z pełnym numerem zamówienia "ZS/AZDEV/008043"
  readonly messages: BasketMessage[]
  readonly needsParameters: boolean
  readonly terms_request: HTTP.Status
  readonly get_params_request: HTTP.Status
  readonly accountType: NullableAccountType
  readonly userDataReady: boolean
  readonly basketReady: boolean
  readonly promoCodeInvalid: boolean
  readonly modal: {
    removeItem: {
      isOpen: boolean
      itemId: number | undefined
      planId: PlanId | undefined
      actionSource: BasketActionSource
      itemRemoval?: ItemRemoval
    }
  }
  readonly domainUpsell: BasketUpsellDomain[]
}

export interface NotificationMessage {
  id: string
  type: "error" | "success" | "info" | "warning" | string
  title: string
  message: string
}

export interface MessageState {
  readonly global: (NotificationMessage | AccountMessage)[]
}

export interface AccountState {
  readonly account: Account | null
}

export interface UserState extends UserContactData {
  tax?: TaxNumberData[]
  company_name?: string
  type: AccountType
  postal_address?: string
  email: string
  user_id?: string | null
}

export interface PaymentRedirectData {
  method: string
  data: any
  url: string
}

export interface CheckoutState {
  readonly request_state: HTTP.Status
  readonly user_request: HTTP.Status
  readonly user?: UserState
  readonly login?: string | null
  readonly email?: string | null
  readonly is_after_login?: boolean

  readonly accountExists: boolean
  readonly isEditModalOpen: boolean
  readonly showRegistration: boolean
  readonly userRegistered: boolean
  readonly userUpdated: boolean

  readonly authUserRequest: HTTP.Status

  // Informacje o płatności.
  readonly payment_system_id?: string
  readonly order_id?: number
  readonly order_number?: string
  readonly redirect_data?: PaymentRedirectData
  readonly modal_form?: PaymentModalData
  readonly is_modal_based_payment: boolean
  readonly bank_transfer_data?: {
    accountNumber: string
    recipient: string[]
    title: string
  }
  readonly is_existing_payment_tool?: boolean
  readonly total_value: number
  readonly total_vat_value: number

  // regulaminy wymagane do zaakceptowania dla usług w aktualnym koszyku
  readonly terms: Term[]
  readonly termsValid?: boolean
  readonly terms_request: HTTP.Status

  // status requestu akceptującego regulaminy
  readonly order_request: HTTP.Status

  // Parametry elementów koszyka potrzebne do wypełnienia przez użytkownika
  readonly params: Record<string, BasketParamListItem>
  readonly get_params_request: HTTP.Status
  readonly save_params_request: HTTP.Status

  readonly modal: {
    confirmAddress: {
      isOpen: boolean
      user?: CompanyData | PersonData
    }
  }
}
