export enum SquidexReferenceType {
  BUNDLE_BUTTON_WITH_PRICE = "BundleButtonWithPrice",
  DOMAIN_BUTTON_WITH_PRICE = "DomainButtonWithPrice",
  CLOUDBLUE_DOMAIN = "CloudblueDomain",
  CLOUDBLUE_PLAN = "CloudbluePlan",
  CTA_ELEMENT = "CtaElement",
  CTA_SECTIONS_MODAL = "CtaSectionsModal",
  STATIC_PRICE = "StaticPrice",
  DOMAIN_EXTENSIONS_UPSELL = "DomainExtensionsUpsell",
  DOMAINS_POOL_UPSELL = "DomainsPoolUpsell",
}
