import { PeriodInfo } from "@core/period-info"
import type { CloudBluePeriod } from "@gatsby-plugin-definitions/fragments/CloudBluePeriod"
import type { CloudBluePlan } from "@gatsby-plugin-definitions/fragments/CloudBluePlan"
import type {
  ResolvedOptionsMapping,
  SaleConfiguration,
} from "@gatsby-plugin-definitions/lib/sale-configuration"
import { getSelectedPeriod } from "~/lib/plan"
import type { ProductPriceNested } from "~/lib/pricing"
import type { PeriodName } from "../lib/api/types"

export function getSelectedPlanSettings(
  optionsMapping: ResolvedOptionsMapping[],
  options: SaleConfiguration.Options,
  defaultOptions: ResolvedOptionsMapping
): ResolvedOptionsMapping {
  if (Object.keys(optionsMapping || {}).length === 0) {
    return {
      ...defaultOptions,
    }
  }

  const optionsKeys: Array<string> = []

  Object.keys(options || {})
    .sort()
    .forEach((key: string) => {
      optionsKeys.push(`${key}[${options[key]}]`)
    })

  const keyPath: string = optionsKeys.join(";")

  const optionsMappingValues = {}

  Object.values(optionsMapping).forEach((option) => {
    if (option.value === keyPath) {
      optionsMappingValues[option.value] = option
    }
  })

  if (optionsMappingValues[keyPath]) {
    return optionsMappingValues[keyPath]
  }

  if (defaultOptions.productData) {
    return defaultOptions
  }

  const firstOptions: ResolvedOptionsMapping | undefined =
    Object.values<ResolvedOptionsMapping>(optionsMapping)[0]

  if (firstOptions) {
    return firstOptions
  }

  throw new Error(`SaleConfiguration - Unknown configuration path ${keyPath}`)
}

interface PrepareComponentValuesResult {
  priceValues: ProductPriceNested
  period?: PeriodName
  periodText: string
}

export function prepareComponentValues(
  optionsMapping: ResolvedOptionsMapping[],
  options: SaleConfiguration.Options,
  defaultOptions: ResolvedOptionsMapping,
  defaultPlan: CloudBluePlan | undefined
): PrepareComponentValuesResult | null {
  try {
    const { productData, period, bundleData } = getSelectedPlanSettings(
      optionsMapping,
      options,
      defaultOptions
    )

    const product =
      productData || (defaultPlan?.flatData as CloudBluePlan["flatData"])

    const selectedPeriod: CloudBluePeriod = getSelectedPeriod(product, period)
    const selectedPeriodInfo = new PeriodInfo(
      selectedPeriod,
      bundleData ? getSelectedPeriod(bundleData) : undefined
    )

    const usePrices = selectedPeriodInfo.usePrices()
    const hasPromotion = selectedPeriodInfo?.hasPromotion() ?? false

    const priceValues: ProductPriceNested = {
      price: selectedPeriodInfo.getRegisterPriceValue(),
      period: selectedPeriodInfo.getPeriodName(),
      regularPrice: selectedPeriodInfo.getRegularPriceValue(false),
      renewPrice: selectedPeriodInfo.getRenewalPriceValue(),
      priceValue: usePrices.priceValue,
      lowestPrice: usePrices.lowestPrice,
      lowestPricePercent: usePrices.lowestPricePercent,
      hasPromotion: hasPromotion,
    }

    return {
      priceValues,
      period,
      periodText: selectedPeriodInfo.periodText(),
    }
  } catch (error) {
    return null
  }
}
