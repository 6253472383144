import React from "react"
import type { ReactElement, ReactNode } from "react"
import { typeMatches } from "@onestore-graphql"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import SurfaceOrg from "@onestore/hel/dist/components/organisms/SurfaceOrg"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import Forms from "@gatsby-plugin-generic-page/components/Forms"
import Header from "@gatsby-plugin-generic-page/components/Header"
import type { Formily as FormilyType } from "@gatsby-plugin-generic-page/fragments/formily"
import type { FormilyWithThankYou } from "@gatsby-plugin-generic-page/fragments/formilyWithThankYou"
import type { FormSurveySectionFlatData } from "@gatsby-plugin-generic-page/fragments/formSurveySection"
import { SquidexFormType } from "@gatsby-plugin-generic-page/types/formType"
import type { Form } from "~/fragments/form"

function FormSurveySection({
  header,
  form,
  formBackgroundColor,
  sectionSettings,
}: FormSurveySectionFlatData): ReactElement<FormSurveySectionFlatData> {
  let formElement: ReactNode = null

  if (typeMatches(form[0].__typename, SquidexFormType.FORM)) {
    const formData = form[0] as Form

    formElement = (
      <Forms
        forms={[formData]}
        formBackgroundColor={formBackgroundColor?.background}
      />
    )
  } else if (typeMatches(form[0].__typename, SquidexFormType.FORMILY_FORM)) {
    const formilyForm = form[0] as FormilyType

    formElement = (
      <Forms
        forms={[formilyForm]}
        formBackgroundColor={formBackgroundColor?.background}
        spacingSpace={{
          small: 3,
          medium: 4,
          large:
            sectionSettings?.containerSettings?.containerWidth === "medium"
              ? { horizontal: 15, vertical: 4 }
              : 6,
        }}
      />
    )
  } else if (
    typeMatches(form[0].__typename, SquidexFormType.FORMILY_FORM_WITH_THANK_YOU)
  ) {
    const formilyFormWithThankYou = form[0] as FormilyWithThankYou

    formElement = (
      <Forms
        forms={[formilyFormWithThankYou]}
        formBackgroundColor={formBackgroundColor?.background}
        spacingSpace={{
          small: 3,
          medium: 4,
          large:
            sectionSettings?.containerSettings?.containerWidth === "medium"
              ? { horizontal: 15, vertical: 4 }
              : 6,
        }}
      />
    )
  }

  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        {header ? (
          <PushOrg bottomSpace={2}>
            <SurfaceOrg shadow="tiny" color="backgroundLight" spacingSpace={6}>
              <Header {...header} />
            </SurfaceOrg>
          </PushOrg>
        ) : null}

        {formElement}
      </BaseContainer>
    </BaseSection>
  )
}

export default FormSurveySection
