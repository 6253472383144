import type { ReactElement } from "react"
import React from "react"
import ContentWithImageOrg from "@onestore/hel/dist/components/organisms/ContentWithImageOrg"
import GridOrg from "@onestore/hel/dist/components/organisms/GridOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import useBreakpoint from "@onestore/hel/dist/hooks/useBreakpoint"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import CtaElement from "@gatsby-plugin-generic-page/components/CtaElement"
import Forms from "@gatsby-plugin-generic-page/components/Forms"
import Header from "@gatsby-plugin-generic-page/components/Header"
import Media from "@gatsby-plugin-generic-page/components/Media"
import Paragraph from "@gatsby-plugin-generic-page/components/Paragraph"
import ToggleWithLinkButtons from "@gatsby-plugin-generic-page/components/ToggleWithLinkButtons"
import type { ContentWithImageAndFormSectionFlatData } from "@gatsby-plugin-generic-page/fragments/contentWithImageAndFormSection"
import isEmpty from "~/lib/isEmpty"

export default function ContentWithImageAndFormSection({
  header,
  toggleWithLinkButtons,
  responsiveImages,
  paragraphs,
  ctaElements,
  form,
  isReversedOnMobile,
  hasImageStickyToBottom,
  sectionSettings,
}: ContentWithImageAndFormSectionFlatData): ReactElement<ContentWithImageAndFormSectionFlatData> {
  const isSmallOnly = useBreakpoint("small", "medium")

  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        <GridOrg
          columns={{ small: 1, medium: 2, large: 2 }}
          gutterSpace={{ small: 6, medium: 6, large: 16.5 }}
          isReversed={isSmallOnly ? isReversedOnMobile : undefined}
        >
          <ContentWithImageOrg
            isImageHiddenOnSmall
            hasImageUnderContent
            hasImageStickyToBottom={hasImageStickyToBottom}
            image={<Media media={responsiveImages} />}
            content={
              <>
                {!isEmpty(toggleWithLinkButtons) ? (
                  <PushOrg bottomSpace={{ small: 4, medium: 4, large: 5 }}>
                    <ToggleWithLinkButtons {...toggleWithLinkButtons} />
                  </PushOrg>
                ) : null}

                <PushOrg bottomSpace={3}>
                  <Header {...header} />
                </PushOrg>

                {paragraphs.map((paragraph, index) => (
                  <PushOrg key={index} bottomSpace={3}>
                    <Paragraph {...paragraph} />
                  </PushOrg>
                ))}

                {ctaElements ? (
                  <PushOrg topSpace={4}>
                    <CtaElement ctaElement={ctaElements} />
                  </PushOrg>
                ) : null}
              </>
            }
          />

          <div>
            <Forms forms={form} formBackgroundColor="backgroundLight" />
          </div>
        </GridOrg>
      </BaseContainer>
    </BaseSection>
  )
}
