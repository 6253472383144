import React from "react"
import type { ReactElement } from "react"
import _t from "@core/i18n"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import CountdownMol from "@onestore/hel/dist/components/molecules/CountdownMol"
import type Theme from "@onestore/hel/dist/typings/theme"
import type { Countdown as CountdownProps } from "@gatsby-plugin-generic-page/types/sectionType"
import useCountdown from "../../hooks/useCountdown"

interface Props extends Omit<CountdownProps, "__typename"> {
  labelTypography?: Theme.Typography
  digitTypography?: Theme.Typography
}

function Countdown({
  from,
  to,
  textBefore,
  text,
  textAfter,
  labelTypography,
  digitTypography,
  isDark,
  isSmall,
}: Props): ReactElement {
  const { days, hours, minutes, seconds } = useCountdown(to)

  const currentTime = new Date()
  const startTime = new Date(from)
  const endTime = new Date(to)

  const isBeforeStart = startTime > currentTime
  const hasStarted = currentTime > startTime && currentTime < endTime
  const hasFinished = currentTime > endTime

  const countdownJsx = (
    <>
      {text ? (
        <TextAtm typography="medium3_h" pushSpace={1}>
          {text}
        </TextAtm>
      ) : null}

      <CountdownMol
        values={[
          {
            value: hasFinished ? "0" : days,
            label: `${_t("time.days")}`,
          },
          {
            value: hasFinished ? "0" : hours,
            label: `${_t("time.hours")}`,
          },
          {
            value: hasFinished ? "0" : minutes,
            label: `${_t("time.minutes")}`,
          },
          {
            value: hasFinished ? "0" : seconds,
            label: `${_t("time.seconds")}`,
          },
        ]}
        labelTypography={labelTypography}
        digitTypography={digitTypography}
        digitBackground={!!isDark ? "customDark" : "customLight"}
        digitColor={!!isDark ? "white" : "dark"}
        isSmall={isSmall}
      />
    </>
  )

  if (isBeforeStart) {
    return <TextAtm typography="medium3_h">{textBefore}</TextAtm>
  } else if (hasStarted) {
    return countdownJsx
  } else {
    return <TextAtm typography="medium3_h">{textAfter}</TextAtm>
  }
}

export default Countdown
