import type { ReactElement } from "react"
import { useContext } from "react"
import React from "react"
import _t from "@core/i18n"
import { hasNoTaxRate } from "@core/pricing"
import { PriceType } from "@core/types"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import UnderlineLinkAtm from "@onestore/hel/dist/components/atoms/UnderlineLinkAtm"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import DomainSearchResultItemsOrg from "@onestore/hel/dist/components/organisms/DomainSearchResultItemsOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import useBreakpoint from "@onestore/hel/dist/hooks/useBreakpoint"
import {
  isResponsePending,
  isResponseSuccessful,
} from "@onestore/onestore-store-common/http"
import type { BundleDomainsResult } from "@gatsby-plugin-bonus/components/DomainsBonusBox/BundleRow"
import BundleRow from "@gatsby-plugin-bonus/components/DomainsBonusBox/BundleRow"
import { DomainsBonusBoxPlaceholder } from "@gatsby-plugin-bonus/components/DomainsBonusBox/index"
import SearchRow from "@gatsby-plugin-bonus/components/DomainsBonusBox/SearchRow"
import DomainPriceTypeContext from "@gatsby-plugin-domain-search/context/DomainPriceTypeContext"
import url from "~/lib/url"
import { COMPONENT_ID } from "~/types/component"
import type { DomainCheck } from "../../../../../lib/api/domainSearch"

interface ContentProps {
  domainSearchUrl?: boolean
  results: DomainCheck.Result[]
  requestStatus: number
  onBasketAdd: {
    (
      domain: string,
      domainId: number,
      buttonId: string,
      ignoreHack: boolean
    ): void
  }
  basketDomains: string[]
  onNextStepClick?: { (): void } | undefined
  hasProduct: boolean
  bundleDomainsResult?: BundleDomainsResult | null
  phrase: string
}

function Content({
  requestStatus,
  phrase,
  results,
  basketDomains,
  onBasketAdd,
  onNextStepClick,
  hasProduct,
  domainSearchUrl,
  bundleDomainsResult,
}: ContentProps): ReactElement {
  const isMediumUp = useBreakpoint("medium")
  const { currentPriceType } = useContext(DomainPriceTypeContext) || {}

  const searchUrl = phrase
    ? `${url.getDomainSearchUrl()}#d:${phrase}`
    : url.getDomainSearchUrl() || "#"

  if (isResponsePending(requestStatus)) {
    return <DomainsBonusBoxPlaceholder />
  }

  return (
    <>
      {isResponseSuccessful(requestStatus) && (
        <PushOrg bottomSpace={3}>
          <DomainSearchResultItemsOrg
            id={COMPONENT_ID.DOMAIN_SEARCH_SECTION_SECONDARY}
            bundle={
              bundleDomainsResult &&
              !bundleDomainsResult.isPartialBundleInBasket ? (
                <BundleRow {...bundleDomainsResult} />
              ) : null
            }
            items={
              <>
                {results.map((result) => {
                  return (
                    <SearchRow
                      key={`domain-result-${result.plan_id}`}
                      basketDomains={basketDomains}
                      result={result}
                      onClick={onBasketAdd}
                      onNextStepClick={hasProduct ? onNextStepClick : undefined}
                    />
                  )
                })}
              </>
            }
          />
          <PushOrg topSpace={1}>
            <TextAtm
              typography="nano1"
              emphasis="medium"
              align={isMediumUp ? "right" : "center"}
            >
              {hasNoTaxRate() || currentPriceType === PriceType.NETTO
                ? _t("domainSearch.mainDomainNoTaxFooter")
                : _t("domainSearch.mainDomainTaxFooter")}
            </TextAtm>
          </PushOrg>
        </PushOrg>
      )}

      {domainSearchUrl ? (
        <AlignContentOrg horizontalAlign={isMediumUp ? "right" : "center"}>
          <UnderlineLinkAtm
            href={searchUrl}
            typography="tiny2"
            title={_t("bonus.domainsGoToSearch")}
          >
            {_t("bonus.domainsGoToSearch")}
          </UnderlineLinkAtm>
        </AlignContentOrg>
      ) : null}
    </>
  )
}

export default Content
